@import "../../colors";

.doughnut-legend {
  min-width: 140px;
  margin-top: 40px;

  &__item {
    display: flex;
    flex-direction: row;
    margin-bottom: 15px;
    margin-left: 15px;

    .color-sector {
      min-width: 15px;
      height: 15px;
      margin-top: 5px;
      margin-right: 5px;
      border-radius: 50%;
    }

    .title-sector {
      display: flex;
      flex-direction: column;

      font-size: 14px;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: normal;
      font-weight: bold;

      &__title {
        line-height: 21px;
      }

      &__subtitle {
        font-weight: normal;
      }
    }

  }
}
