@import '../../colors';

.tab-content-header {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  color: $navy;
  min-height: 80px;

  &__label {
    margin-bottom: 20px;
    .title {
      font-size: 24px;
      font-weight: 600;
    }

    .subtitle {
      font-size: 14px;
      color: $black;
    }
  }

  &__filters {
    display: flex;
    flex-flow: row wrap;

    .wfp--inline-loading{
      width: auto;
    }

    .filter-item {
      width: 115px;

      &__label {
        line-height: 1.29;
        color: $navy;
        font-size: 14px;
        font-weight: 600;
        padding-bottom: 7px;
      }
    }

    .filter-item:not(:last-child) {
      margin-right: 10px;
      margin-bottom: 10px;
    }
  }
}
