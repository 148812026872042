// ----------------------------------------------------------------------------
// Layout: Pages
// ----------------------------------------------------------------------------

.monthly-pipeline-help {
  padding-top: 10px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  &__title {
    color: black;
    padding-bottom: 10px;
    padding-left: 5px;
  }

  &__table {
    table-layout: fixed;
    width: 50%;
    border-collapse: separate;
    border-spacing: 5px;

    td {
      width: 25%;
      height: 30px;
      text-align: center;
      vertical-align: middle;
    }
  }

  &__tooltip {
    width: 100%;
    height: 30px;
    cursor: pointer;

    &__item {
      height: 100%;
      color: rgba(255, 255, 255, 1);
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-right: 5px;
    }
  }
}

.monthly-pipeline__table {
  .rt-th.-sort-asc:after, .rt-th.-sort-desc:after {
    padding: 0 5px;
    z-index: 90;
  }
}

.outcome-indicators {
  &__selected-items {
    display: flex;
    flex-direction: row;

    .wfp--form-item {
      padding: 0 10px;
    }
  }

  &__table {
    .performance-block {
      width: 100%;
      height: 25px;
    }

    .rt-resizable-header-content {
      font-size: 12px;
    }

    .rt-td {
      font-size: 14px;
    }
  }

  &__simple-chart {
    height: 30px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;

    .bar {
      margin: 0 1px;
      width: 25%;
      cursor: pointer;
    }
  }
}

.wfp--form-item {
  select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    font-size: 0.800rem;
  }

  select::-ms-expand {
    display: none;
  }
}

.partners-overview-table {
  &__logo {
    width: 100%;
    max-width: 130px;
    max-height: 130px;
    vertical-align: top;
  }
}

.map--bottom {
  padding-right: 0;
  padding-left: 0;
}

.loading-bar {
  padding-bottom: 15px;
  margin-top: 1rem;
}

.value-select__selector__content div {
  cursor: pointer;
}

.region-level-situation {
  &__table {
    min-height: 50px;

    .plan-actual-column {
      display: flex;
      flex-direction: column;
      width: 100%;

      &__row {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 5px 0;
      }

      &__progress-bar {
        height: 7px;
        width: 100%;
        background-color: #D8D8D8;

        span {
          display: block;
          height: 7px;
        }
      }
    }
  }

  &__tooltip {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}

.meta-info-list {
  font-size: 0.8em;
  font-weight: 400;

  &__item {
    margin: 0.4em 0;
  }

  &__title {
    font-weight: 600;
    font-size: 1.1em;
  }

  &__sub-title {
    padding-left: 5px;
    font-weight: 500;
  }
}

.value-list {
  display: flex;
  flex-direction: column;

  &__links {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 15px;
    grid-gap: 10px;
  }
}

.audit-row {
  padding: 0 !important;

  &__item {
    width: 100%;
    height: 100%;
    padding: 7px 0.5em;
  }

}

.wfp--module {
  &__extra_title {
    font-size: 12px;
  }
}

.custom-inline-title {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;


  small {
    font-size: 12px;
    padding-left: 5px;
  }

}

.small-unit {
  font-weight: 300;
  margin-left: 0.2em;
  font-size: 0.7em;
  font-style: italic;
}


.footer {
  &.wfp--footer {
    z-index: unset;
  }

  .wfp--footer__content {
    align-items: flex-start;

    .wfp--footer__cta {
      margin-top: 17px;
    }
  }

  &-links {
    margin-right: 80px;

    &:last-child {
      margin-right: 5px;
    }

    &__mobile-apps {
      display: flex;
    }

    h3 {
      font-size: 0.875rem;
      line-height: 1.5;
      font-weight: 600;
      margin: 0;
    }

    img {
      height: 50px;
    }

    &__image-wrapper {
      margin-right: 20px;
    }

    &__image-wrapper:last-child {
      margin-right: 0;
    }

    &__list {

      li {
        line-height: 1.5;
      }

      a {
        font-weight: 400;
        text-decoration: none;
      }

      a:hover {
        text-decoration: underline;
      }
    }
  }
}

@media (max-width: 1200px) {
  .footer {
    &-links__mobile-apps {
      flex-direction: column;
    }
  }
}

@media (max-width: 940px) {
  .footer {

    .wfp--footer__cta {

      &-logo, .wfp--footer__sdg {
        display: none;
      }

      &-logo-small {
        display: block;
      }

    }
  }
}

@media (max-width: 720px) {
  .footer {

    &-links {
      margin-right: 50px;

      &__list a {
        font-size: 14px;
      }

      &__mobile-apps img, .google-play-link img {
        height: 40px;
      }
    }
  }
}

@media (max-width: 610px) {
  .footer {
    position: relative;
    .wfp--wrapper {

      .wfp--footer__content {
        flex-direction: column-reverse;
        align-items: flex-start;
      }

      .wfp--footer__info {
        flex-direction: column;
      }

      .wfp--footer__cta {
        position: absolute;
        top: 20px;
        right: 16px;
        margin-top: 0;
      }
    }

    &-links {
      margin-bottom: 10px;
      margin-right: 0;

      &__mobile-apps {
        flex-direction: row;
        flex-wrap: wrap;
      }
    }
  }
}

.footer-contacts {
  &__items {
    padding-left: 20px;

    .footer-email-icon {
      margin-right: 10px;
    }
  }
}

.content-wrap {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.content{
  flex-grow: 1;
}

.footer {
  flex-shrink: 0;

  .wfp--footer__content{
    flex-flow: row wrap;
  }
}
