.main-dashboard {
  &__panels {
    display: grid;
    grid-gap: 20px;
    margin-bottom: 20px;
    grid-template-columns: repeat(auto-fit, minmax(390px, 1fr));
    &-left {
      grid-template-columns: 1.35fr 0.65fr;
    }
    &-right {
      grid-template-columns: 0.65fr 1.35fr;
    }
  }
}

.implementation-plan-panel {
  height: 100%;
  & .indicator-panel__bottom {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
}

.big-panel {
margin-top: 20px;
margin-bottom: 60px;

.panel-v2 {
.wfp--module__content {
  padding-top: 0;
  padding-bottom: 0;
}
}
}


@media screen and (max-width: 760px) {
  .main-dashboard {
    &__panels {
      grid-template-columns: 1fr;
    }
  }
}
