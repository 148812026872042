.legend {
  list-style-type: none;
  margin: 0;
  padding: 0;
  font-size: 0.7rem;
  line-height: 1.0rem;

  &__label {
    font-style: normal;
    font-weight: normal;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;

    .bold {
      font-weight: bold;
      color: #000;
    }

    .percents {
      width: 40px;
      text-align: right;
    }

    .value {

      &-container {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;

        .percents {
          margin-left: 10px;
        }

      }
      text-align: right;
    }
  }
}
