.top-countries-panel {
  .chart-indicator-panel {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 2px;
    background-color: #E1E7EB;
    flex-wrap: wrap;

    .indicators {
      .title {
        &__subtitle {
          .tooltip {
            position: relative;
            width: auto;
            color: var(--interactive-01);
            text-decoration: underline;
          }
        }
      }
    }

    .title {
      display: flex;
      flex-direction: column;
      line-height: 26px;
      font-size: 16px;
      font-weight: 600;

      &__subtitle {
        margin-bottom: 5px;
        line-height: 23px;
        font-size: 14px;
        font-weight: 600;
        color: #5a6872;
      }
    }

  }

  .chart-indicator-panel__bottom {
    padding-left: 15px;
    background: #fff;

    .bar-indicator-row__desc {
      width: initial;
      min-width: initial;
    }
  }

  &__inner {
    padding-right: 15px;
    background: #fff;

    .chart {
      width: 100%;
      margin: 0;

      .legend {
        margin-top: 20px;
      }
    }

    .doughnut-chart__container {
      margin-top: -20px;
      align-self: center;
    }
  }

  .legend-wrap {
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    grid-gap: 5px;

    .dash-legend {
      margin-top: 0;
    }
  }
}

@media (max-width: 1000px) {
  .top-countries-panel {
    .chart-indicator-panel {
      grid-template-columns: 1fr 1fr;
    }
  }
}

@media (max-width: 700px) {
  .top-countries-panel {
    .chart-indicator-panel {
      grid-template-columns: 1fr;
    }

    .chart-indicator-panel__bottom {
      padding: 15px 0 0 0;
    }

    &__inner {
      padding: 0 0 15px 0;
    }
  }
}
