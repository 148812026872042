@import "../colors";

// tippy custom theme
.tippy-tooltip.dark-v2-theme {
  background-color: $pickled-bluewood;
  color: white;
}

.hamburger-theme {
  background: #fff;
}

.tippy-tooltip.dark-v2-theme[data-animatefill] {
  background-color: transparent;
}

.tippy-tooltip.dark-v2-theme .tippy-backdrop {
  background-color: $pickled-bluewood;
}
