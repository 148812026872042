@mixin enter-transform($position, $animation) {
	@if ($animation == 'perspective') {
		@if ($position == 'top') {
			transform: translateY(-10px) rotateX(0);
		} @else if ($position == 'bottom') {
			transform: translateY(10px) rotateX(0);
		} @else if ($position == 'left') {
			transform: translateX(-10px) rotateY(0);
		} @else if ($position == 'right') {
			transform: translateX(10px) rotateY(0);
		}
	} @else if ($animation == 'fade') {
		@if ($position == 'top') {
			transform: translateY(-10px);
		} @else if ($position == 'bottom') {
			transform: translateY(10px);
		} @else if ($position == 'left') {
			transform: translateX(-10px);
		} @else if ($position == 'right') {
			transform: translateX(10px);
		}
	} @else if ($animation == 'scale') {
		@if ($position == 'top') {
			transform: translateY(-10px) scale(1);
		} @else if ($position == 'bottom') {
			transform: translateY(10px) scale(1);
		} @else if ($position == 'left') {
			transform: translateX(-10px) scale(1);
		} @else if ($position == 'right') {
			transform: translateX(10px) scale(1);
		}
	}
}

@mixin leave-transform($position, $animation) {
	@if ($animation == 'perspective') {
		@if ($position == 'top') {
			transform: translateY(0) rotateX(90deg);
		} @else if ($position == 'bottom') {
			transform: translateY(0) rotateX(-90deg);
		} @else if ($position == 'left') {
			transform: translateX(0) rotateY(-90deg);
		} @else if ($position == 'right') {
			transform: translateX(0) rotateY(90deg);
		}
	} @else if ($animation == 'shift') {
		@if ($position == 'top') {
			transform: translateY(0);
		} @else if ($position == 'bottom') {
			transform: translateY(0);
		} @else if ($position == 'left') {
			transform: translateX(0);
		} @else if ($position == 'right') {
			transform: translateX(0);
		}
	} @else if ($animation == 'scale') {
		@if ($position == 'top') {
			transform: translateY(0) scale(0);
		} @else if ($position == 'bottom') {
			transform: translateY(0) scale(0);
		} @else if ($position == 'left') {
			transform: translateX(0) scale(0);
		} @else if ($position == 'right') {
			transform: translateX(0) scale(0);
		}
	} @else if ($animation == 'none') {
		@if ($position == 'top') {
			transform: translateY(-10px);
		} @else if ($position == 'bottom') {
			transform: translateY(10px);
		} @else if ($position == 'left') {
			transform: translateX(-10px);
		} @else if ($position == 'right') {
			transform: translateX(10px);
		}
	}
}

@mixin circle-transform-leave($position) {
	$scale: 0.45;
	@if ($position == 'top') {
		transform: scale($scale) translate(-50%, -100%);
	} @else if ($position == 'bottom') {
		transform: scale($scale) translate(-50%, 8%);
	} @else if ($position == 'left') {
		transform: scale($scale) translate(-50%, -50%);
	} @else if ($position == 'right') {
		transform: scale($scale) translate(-50%, -50%);
	}
}

@mixin arrow($position, $width, $color) {
	@if ($position == 'top') {
		border-top: $width solid $color;
		border-right: $width solid transparent;
		border-left: $width solid transparent;
	} @else if ($position == 'bottom') {
		border-bottom: $width solid $color;
		border-right: $width solid transparent;
		border-left: $width solid transparent;
	} @else if ($position == 'left') {
		border-left: $width solid $color;
		border-top: $width solid transparent;
		border-bottom: $width solid transparent;
	} @else if ($position == 'right') {
		border-right: $width solid $color;
		border-top: $width solid transparent;
		border-bottom: $width solid transparent;
	}
}

@mixin arrow-position($position, $width) {
	@if ($position == 'top') {
		bottom: -$width;
	} @else if ($position == 'bottom') {
		top: -$width;
	} @else if ($position == 'left') {
		right: -$width;
	} @else if ($position == 'right') {
		left: -$width;
	}
}

.tooltip--link {
	cursor: pointer;
}

.tooltip--source {
	display: none;
}

.tippy-tooltip-content {
	text-align: left;
	.wfp-grid {
		margin: 0.7em -0.8rem 1em -0.8rem;
		//padding: 0.4rem 0.8rem;
		border-top: 1px solid $grey-lighter;
		border-bottom: 1px solid $grey-lighter;
		> span {
			//border-right: 1px solid $grey-lighter;
			padding: 0.7em;
		}
	}
	/*table {
        width: 100%;
        text-align: left;
        margin: 1em 0;
        td {
            width: 50%;
            text-align: right;
        }
    }*/
	.heading--smallest {
		color: $brand-01;
		margin-bottom: 0.5em;
	}

	/*
.tooltip--content {
    background: white;
    position: absolute;
    top: 0;
    right: 0;
    box-shadow: 2px 10px 30px -2px rgba(#000,0.1);
    min-width: 350px;
    max-height: 500px;
    overflow-y: auto;
    z-index: 1000;
    padding: 1rem;
    border: 1px solid $grey-lighter;
    border-radius: 5px;
    font-size: 0.9em;

}

.tooltip--nopadding {
    padding: 0;
} */
}

.tooltip--nopadding {
	padding: 0;
}

$positions: 'top', 'bottom', 'left', 'right';
$origins: bottom, top, right, left;
$circle-origins: 0% 100%, 0% -100%, 50% 0%, -50% 0%;

.tippy-touch {
	cursor: pointer !important;
}

.tippy-notransition {
	transition: none !important;
}

.tippy-box {
	color: #26323d;
	box-shadow: 0 4px 20px 4px rgb(0 20 60 / 10%), 0 4px 80px -8px rgb(0 20 60 / 20%);
	background-color: #ffffff;

	.tippy-content {
		font-size: 0.95rem;
		padding: 0.5rem 0.8rem;
	}

	.tippy-arrow {
		color: #ffffff;
	}
}

.tippy-popper {
	max-width: 380px;
	perspective: 800px;
	z-index: 2 !important;
	outline: 0;
	pointer-events: none;
	&.html-template {
		max-width: 96%;
		max-width: calc(100% - 20px);
	}

	@each $position in $positions {
		&[x-placement^='#{$position}'] {
			[x-arrow] {
				position: absolute;
				width: 0;
				height: 0;
				@include arrow($position, 7px, #333);
				@include arrow-position($position, 7px);

				&.arrow-small {
					@include arrow($position, 5px, #333);
					@include arrow-position($position, 5px);
				}

				&.arrow-big {
					@include arrow($position, 10px, #333);
					@include arrow-position($position, 10px);
				}
			}

			[x-circle] {
				transform-origin: nth($circle-origins, index($positions, $position));

				&.enter {
					transform: scale(1) translate(-50%, -50%);
					opacity: 1;
				}

				&.leave {
					@include circle-transform-leave($position);
					opacity: 0;
				}
			}

			.tippy-tooltip.light-theme {
				[x-circle] {
					background-color: white;
				}
				[x-arrow] {
					@include arrow($position, 7px, white);

					&.arrow-small {
						@include arrow($position, 5px, white);
					}

					&.arrow-big {
						@include arrow($position, 10px, white);
					}
				}
			}

			.tippy-tooltip.transparent-theme {
				[x-circle] {
					background-color: rgba(0, 0, 0, 0.7);
				}
				[x-arrow] {
					@include arrow($position, 7px, rgba(0, 0, 0, 0.7));

					&.arrow-small {
						@include arrow($position, 5px, rgba(0, 0, 0, 0.7));
					}

					&.arrow-big {
						@include arrow($position, 10px, rgba(0, 0, 0, 0.7));
					}
				}
			}

			[data-animation='perspective'] {
				transform-origin: nth($origins, index($positions, $position));

				&.enter {
					opacity: 1;
					@include enter-transform($position, 'perspective');
				}

				&.leave {
					opacity: 0;
					@include leave-transform($position, 'perspective');
				}
			}

			[data-animation='fade'] {
				&.enter {
					opacity: 1;
					@include enter-transform($position, 'fade');
				}
				&.leave {
					opacity: 0;
					@include enter-transform($position, 'fade');
				}
			}

			[data-animation='shift'] {
				&.enter {
					opacity: 1;
					@include enter-transform($position, 'fade');
				}
				&.leave {
					opacity: 0;
					@include leave-transform($position, 'shift');
				}
			}

			[data-animation='scale'] {
				&.enter {
					opacity: 1;
					@include enter-transform($position, 'scale');
				}
				&.leave {
					opacity: 0;
					@include leave-transform($position, 'scale');
				}
			}
		}
	}

	.tippy-tooltip.transparent-theme {
		background-color: rgba(0, 0, 0, 0.7);

		&[data-animatefill] {
			background-color: transparent;
		}
	}

	.tippy-tooltip.light-theme {
		color: #26323d;
		box-shadow: 0 4px 20px 4px rgba(0, 20, 60, 0.1),
			0 4px 80px -8px rgba(0, 20, 60, 0.2);
		background-color: white;

		&[data-animatefill] {
			background-color: transparent;
		}
	}
}

.tippy-tooltip {
	position: relative;
	color: white;
	border-radius: 4px;
	font-size: 0.95rem;
	padding: 0.5rem 0.8rem !important;
	//text-align: center;
	will-change: transform;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #333;
	pointer-events: none;

	&--small {
		padding: 0.25rem 0.5rem;
		font-size: 0.8rem;
	}

	&--big {
		padding: 0.6rem 1.2rem;
		font-size: 1.2rem;
	}

	&[data-animatefill] {
		overflow: hidden;
		background-color: transparent;
	}

	&[data-interactive] {
		pointer-events: auto;
	}

	&[data-inertia] {
		// Safari/Webkit can't go out of range
		-webkit-transition-timing-function: cubic-bezier(0.53, 1, 0.36, 0.85);
		transition-timing-function: cubic-bezier(0.53, 2, 0.36, 0.85);
	}

	[x-circle] {
		position: absolute;
		will-change: transform;
		background-color: #333;
		border-radius: 50%;
		width: 120%;
		width: calc(100% + 2rem);
		transition: all ease-out;
		left: 50%;
		top: 50%;
		z-index: -1;
		overflow: hidden;

		&::before {
			content: '';
			padding-top: 75%;
			float: left;
		}
	}
}

@media (max-width: 450px) {
	.tippy-popper {
		max-width: 96%;
		max-width: calc(100% - 20px);
	}
}

.market-prices-tips {
	display: flex;
	flex-direction: column;
	font-size: 14px;
	text-align: left;

	.selected {
		background-color: rgba(#cccccc, 0.3);
		font-weight: 600;
	}
}
