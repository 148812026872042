@import '../colors';

.strategic-results-panel {
	display: flex;

	&__content {
		display: flex;
		.pdf-wrap {
			display: flex;
			.indicator-panel__bottom {
				display: contents;
			}
		}
	}

	.horizontal-bar-chart {
		display: content;
		&__items {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
		}

		&__item-set {
			flex: 1;
		}

		&__separator {
			width: 80px;
		}

		.legend {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;

			.mark-wrap {
				margin-right: 20px;
			}
		}
	}
}

@media (max-width: 876px) {
	.strategic-results-panel {
		.horizontal-bar-chart {
			&__items {
				display: flex;
				flex-direction: column;
			}
		}
	}
}

@media (max-width: 600px) {
	.strategic-results-panel {
		.horizontal-bar-chart {
			&__items {
				grid-template-columns: repeat(auto-fit, minmax(245px, 1fr));
			}
		}
	}
}
