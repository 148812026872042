.story--large {
	font-size: 1.1em;
	font-style: italic;
	margin-top: 1em;
}

.story__highlight, .story__unit {
    //color: $accent;
    //font-style: italic;
    background: $blue-lightest;
    padding: 0.1em 0.3em;
    //font-size: 1.2em;
    //font-weight: bold;
}

.story__highlight {
	font-weight: normal;
}
