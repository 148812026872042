@import "../colors";

.hamburger {

  &-icon {
    cursor: pointer;
    padding: 2px;
    box-sizing: border-box;

    &:hover {
      fill: $secondary-blue;
    }
  }

  &-icon.active {
    outline: 2px solid $nice-blue;

    &:hover {
      fill: $nice-blue;
    }
  }

  &-container {
    margin: 0 25px 0 0;
  }


  &-menu {
    padding: 15px;
    position: absolute;
    transform: translateY(2px);
    box-shadow: 4px 4px 8px 0 rgba(34, 60, 80, 0.2);
    border-radius: 4px;
    font-size: .95rem;
    background: #fff;

    a {
      color: $nice-blue;
      text-decoration: none;
    }

    a:hover {
      color: $dark-blue
    }

    &__item {
      margin-bottom: 20px;

      &-label {
        font-weight: 600;
        font-size: 16px;
      }
    }

    &__item:last-child {
      margin-bottom: 0;
    }

    &__panels {
      display: flex;
      flex-direction: column;
      margin-left: 30px;
    }

    &__panel {

      &-label {
        margin-top: 10px;
        font-size: 14px;
      }
    }
  }
}
