@import "../../colors";

.area-chart {

  &-axis {
    display: flex;
    padding: 0 5px;
    flex-direction: row;
    justify-content: space-between;

    &__item {
      font-size: 10px;
      color: $dark-grey;
      font-family: 'Open Sans', sans-serif;
    }
  }
}
