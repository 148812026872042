@import "../colors";

.switcher {
  &__options {
    display: flex;
    flex-direction: column;

    div {
      cursor: pointer;
      border-bottom: 1px solid $athens-gray;
      color: $water-blue;
      padding: 10px 0;
      text-transform: capitalize;
    }

  }
}