/**
 * Tooltip
 *
 * Tooltip on hover and on click
 * found on all pages.
 */

@import '../v2/colors';

.table-column-title,
.card__title-container {
	&:hover {
		text-decoration: underline;
	}
	&-disabled:hover {

		text-decoration: none;
	}
}
.wfp-tooltip {
	position: relative;
	cursor: pointer;
	user-select: none;
	line-height: 1.3rem;

	&-card {
		display: flex !important;
		justify-content: space-between;
		flex-direction: row;
	}

	&-disabled {
		cursor: initial;
	}

	&__markup {
		ul {
			display: block;
			list-style-type: disc;
			margin-block-start: 1em;
			margin-block-end: 1em;
			margin-inline-start: 0px;
			margin-inline-end: 0px;
			padding-inline-start: 40px;
		}

		li {
			line-height: 1.4;
		}
	}

	a {
		cursor: pointer;
	}

	/* svg & {
      position: static;
      width: 100%;
      height: 100%;
      display: block !important;
  } */
}

.pointer {
	cursor: pointer;
}

.wfp-tooltip--svg {
	position: fixed;
	text-align: center;
	z-index: 1000000;
	transform: translateX(-50%) translateY(-100%);
	transition: all 0.2s;
}

.wfp-tooltip--svg__position {
	position: relative;
	top: -14px;
	transition: all 1s;
}

.wfp-tooltip--svg__inner {
	background: white;
	//font-size: 0.7rem !important;
	width: auto !important;
	height: auto !important;
	margin: auto !important;
	white-space: nowrap;
	font-weight: normal;
	padding: 0.8rem;
	position: static !important;
	will-change: auto !important;

	&:after {
		display: block;
		content: '';
		margin-left: -5px;
		position: absolute;
		bottom: -10px;
		left: 50%;
		width: 10px;
		height: 10px;
		@include arrow('top', 7px, white);
	}

	h3 {
		font-size: 1em;
		line-height: 1.5rem;
	}

	.tooltip--small {
		display: inline-block;
	}

	.unit {
		&:after {
			opacity: 1;
		}
	}
}

.tooltip--content {
	background: white;
	position: absolute;
	top: 0;
	right: 0;
	box-shadow: 2px 10px 30px -2px rgba(#000, 0.1);
	min-width: 350px;
	max-height: 500px;
	overflow-y: auto;
	z-index: 1000;
	padding: 1rem;
	border: 1px solid $grey-lighter;
	border-radius: 5px;
	font-size: 0.9em;

	.tooltip-content__table {
		width: 100%;
		text-align: left;

		td {
			width: 50%;
		}
	}
}

.tooltip--small {
	text-align: center;
}

/* Tooltip List */

.tooltip__list {
	text-align: left;
	font-size: 0.9em;
	width: 100%;
	list-style-type: none;
	margin: 0;
	padding: 0;
}

.tooltip__donor {
	text-align: left;
}

.tooltip__list--title {
	padding-right: 1em;
	font-weight: bold;
	text-align: left !important;
}

.tooltip__list--no-description {
	text-align: center;
}

.tooltip--nopadding {
	padding: 0;
}

.tooltip--close {
	position: absolute;
	top: 1rem;
	right: 1rem;
	border-bottom: 0;
}
