@import '../v2/colors';

@keyframes chart-intro {
    from {transform: scale(0.98);}
    to {transform: scale(1);}
}

.chart {
	margin: auto;
	animation-name: chart-intro;
    animation-duration: 0.2s;
    max-width: 600px;
}

.chart__wrapper {
	svg {
		margin: auto;
	}
	//margin: -2em 0;
}

.chart--travel-cost {
	@include media-max($md-screen) {
		//margin: -3em 0 -2em 0;
		position: relative !important;
	}
	@include media-query($md-screen) {
		position: absolute !important;
		left: -0.5em;
		bottom: -1.75em;
	}
}

.chart--implementation {
	margin-top: 3.7rem;
	margin-bottom: -1rem;
	g {
		clip-path: none !important;
	}
}

.content svg:not(:root){
	overflow: visible;
}

.chart--pie {
	animation-name: chart-intro;
    animation-duration: 0.2s;
	margin: auto;
    margin-bottom: -50px;
    max-width: 600px;
    display: block;
	text, tspan {
		font-family: "Lato",  sans-serif !important;
	}
	path {
		cursor: pointer;
		transition: opacity 0.4s;
		transform-origin: center;
		@include media-query($md-screen) {
			&:hover {
				opacity: 0.8;
				//transform: translate(175px,175px) scale(1.02);
			}
		}
	}
}

.chart--pie--tooltip {
	width: 100%;
	height: 100%;
	font-size: 0.7rem;
	border-radius: 300px;
	text-align: center;
	font-weight: normal;
	background-color: $white;
	display: flex;
	justify-content: center;
	flex-direction: column;
	h3 {
		margin-top: -0.5em;
		font-size: 0.9em;
		line-height: 1.7em;
	}
	.unit {
		&:after {
			opacity: 1;
			color: $grey;
		}
	}
	&.small {
		margin-top: 15px;
		font-size: 0.6rem;
	}
}

.chart--aside {
	margin: -1.3em;
	position: absolute !important;
	left: 0;
	bottom: 0;
}

.chart--small-pie {
	path {
		@include media-query($md-screen) {
			&:hover {
				//transform: translate(75px,75px) scale(1.02);
			}
		}
	}
}

.chart__circle {
	fill: $brand-01;
	stroke: white;
	stroke-width: 1px;
}

.chart__circle--small {
	stroke-width: 0;
	fill: $brand-01;
}

.chart__circle--mini {
	stroke-width: 0;
	fill: $brand-01;
}

@keyframes chart__line {
    from {opacity: 0; transform: scaleY(0.9);}
    to {opacity: 1; transform: scaleY(1);}
}


.chart__line {
	stroke-width: 2px;
    stroke: $dark-grey;
}

.chart__line__invisible {
	opacity: 0;
}


@keyframes chart__line__point {
    from {transform: scale(0.1);}
    to {transform: scale(1);}
}

.chart__line__point {
	fill: #fff;
	stroke: $dark-grey;
	stroke-width: 1;
	transform-origin: center;
}

.chart__tooltip__circle {
	fill: rgba($brand-01,0.5);
	opacity: 0;
	transition: all 0.4s;
	transform-origin: center;
}

.chart__small-wrapper {
	margin: auto;
	width: 50% !important;
}

.chart--pointer {
	path {
		cursor: pointer;
	}
	&:hover {
		z-index: 10;
	}
}

.chart__label {
	opacity: 0.8;
}

.chart__innerlabel {
	tspan {
		font-size: 8px;
		opacity: 0.7;
		//fill: $white !important;
	}
}

/* path:nth-child(1) {
	fill:url(#MyGradient) !important;
} */

.chart__tooltip {
	pointer-events: none;
	font-weight: bold;
	transition: all 0.15s;
	&:hover {
		.chart__tooltip__circle {
			fill: rgba($brand-01,0.3);
			transform: scale(8);
		}
	}
}

.chart--trend {
	margin: 0;
	position: absolute !important;
	left: 0;
	bottom: 0;
}
