@import "../colors";

.panel-v2 {
  .loading__container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 250px
  }

  &__header {

    h1 {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
    }

    .wfp--module__title {
      display: flex;
      flex-flow: wrap row;
      align-items: center;
      font-family: 'Open Sans', sans-serif;
      font-size: 20px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $navy;
    }

    .title-switcher{

    }

    .download{
      cursor: pointer;
    }
  }

  &__content {
    overflow: hidden;

    &-horizontal {
      flex-direction: row;
    }
  }

  &__banner {
    position: absolute;
    width: calc(100% + 500px);
    height: 50px;
    top: 40%;
    left: -50%;
    transform: rotate(46deg);
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      color: #fff;
      font-weight: 600;
      font-size: 18px;
    }

    &-error {
      background: $error-transparent;
    }
  }

  &__footer {
    justify-content: space-between;
    height: 40px;

    .help-text {
      font-size: 12px;
      font-weight: bold;
      color: $blue-grey;
    }

    .info-error {
      display: flex;
      flex-direction: row;
      font-weight: 600;
      color: $lipstick;

      &__text {
        margin-left: 5px;
      }
    }
  }

  .wfp--module__inner {
    height: 100%;
    display: flex;
    overflow: hidden;
    flex-direction: column;
  }
}
