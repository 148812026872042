@import "../../colors";

.dash-legend {
  display: flex;
  margin-top: 16px;

  &__item {
    margin-right: 8px;
    display: flex;
    align-items: center;
  }

  &__sign {
    width: 18px;
    border-width: 1px;
    border-color: #000;
    margin-right: 8px;
  }

  &__label {
    font-size: 13px;
    font-family: "Open Sans", sans-serif;
    color: $slate-grey;
  }
}
