// CSS: Grid
// Project: WFPUI
// Module: Grid
// Project: WFP UI
// Notes: Based on PureCSS Grid

@mixin grid--wrapper {
  letter-spacing: -0.31em;
  text-rendering: optimizespeed;
  font-family: FreeSans, Arimo, "Droid Sans", Helvetica, Arial, sans-serif;
  display: -ms-flexbox;
  display: -webkit-flex;
  -ms-flex-flow: row wrap;
  -ms-align-content: flex-start;
  -webkit-flex-flow: row wrap;
	-webkit-align-content: flex-start;
	align-content: flex-start;
}

@mixin grid--unit {
  display: inline-block;
  zoom: 1;
  letter-spacing: normal;
  word-spacing: normal;
  vertical-align: top;
  text-rendering: auto;
  font-family: $sans-serif-stack;
  box-sizing: border-box;
}


@import "grid-units";

@mixin grid {
  @include grid--wrapper;

  &[class*="wfp-u-"] {
    font-family: $sans-serif-stack;
  }
}

.opera-only :-o-prefocus, .wfp-grid {
  word-spacing: -0.43em;
}

.wfp-grid {
  @include grid;

  [class*="wfp-u-"] {
    @include grid--unit;
  }
}
