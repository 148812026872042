//
// Tables.
// Inspired by https://github.com/twbs/bootstrap/blob/v4-dev/scss/_tables.scss
//

// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

.wfp-table--small {
  font-size: 0.8em;
  th {
    font-size: 0.9em;
  }
}

.wfp-table--responsivewrapper {
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
  @include media-max($md-screen) {
    margin-left: -2%;
    margin-right: -2%;
    width: 104%;
  }
}

.wfp-table__scrollable {
  //TODO: Dynamic height
  height: 318px;
  @include media-query($xl-screen) {
    height: 358px;
  }
  overflow: auto;
}

.wfp-table__rightalign, .wfp-table .value {
  text-align: right;
}

.wfp-table {
  .left {
    text-align: left;
    padding-right: 0;
  }
  .previousyear {
    padding-right: 0;
  }
}

.wfp-table {
  tr:last-child {
    td {
      border-bottom: none;
    }
  }
}

.wfp-table--narrow {
  font-size: 0.95em;
}

/* Cell Stylings */
.cell--logo {
  display: block;
  width: auto;
  height: 32px;
  margin: -0.1em;

  &.small {
    height: 23px;
    border: 1px solid #cccccc;
  }
}

.donors-overview-table {
  height: 20px;
  width: 100%;

  &__tooltip {
    display: flex;
    flex-direction: column;
  }
}

.cell {
  &__bar-chart {
    height: 100%;
    width: 100%;
    position: relative;

    &__bar {
      background-color: $color__orange;
      height: 50%;
      transition: all .5s ease-out;
    }

    &__bar.prev {
      background-color: $color__aqua;
    }
  }

  &__legend {
    display: flex;
    flex-direction: column;
    width: 100%;
    font-size: 12px;

    &__current {
      border-right: 10px solid $color__orange;
      height: 10px;
      margin-bottom: 3px;
    }
    &__prev {
      border-right: 10px solid $color__aqua;
      height: 10px;
    }
  }

}

// Region -> Finance -> Region Level Situation table
// Region -> People -> People Level Situation table
.region-level-situation, .people-level-situation {
  .rt-table {
    .rt-thead {
      font-size: 14px;
      font-weight: 600;
      line-height: 15px;
    }
  }

  &__grouped-row {
    padding-left: 20px;
  }

  &__header-item {
    display: flex;
    flex-direction: column;
  }

  &__subtitle {
    font-size: 10px;
    align-self: flex-end;
    color: #9B9B9B;
  }

  &__country-column {
    font-size: 13px;
    min-height: 50px;
  }

  &__country-code {
    font-size: 14px;
    font-weight: 600;
    color: #15529F;
  }

  &__country-date {
    display: flex;
    flex-direction: column;
    padding: 5px 0;
    color: #9B9B9B;
  }

  &__country-description {
    color: #9B9B9B;
  }

  &__footer-item {
    text-transform: uppercase;
    font-size: 18px;
  }

  .rt-resizable-header-content {
    width: 100%;
  }
}
