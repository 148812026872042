.no-data {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  > div {
    margin: auto;
    line-height: 3em;
  }
}

.no-data, .rt-noData {
  text-align: center;
  font-size: 1.75rem;
  color: $grey;
  width: 100%;
  font-style: italic;
  font-weight: 300;
}