@import "../../colors";

.big-percent-indicator {
  display: flex;
  flex-direction: column;

  &__label {
    font-size: 16px;
    font-weight: 600;
    color: $navy;
    margin-bottom: 15px;
  }

  &__bar {
    height: 25px;
    width: 100%;
    position: relative;

    .right-bar, .left-bar {
      height: 100%;
      width: 100%;
      border-radius: 25px;
    }

    .left-bar {
      background-color: $grey-blue;
      color: white;
      font-size: 16px;
      font-weight: 600;
      text-align: right;
      position: absolute;
      z-index: 5;
      .percent-text{
        padding-right: 8px;
      }
    }

    .right-bar {
      width: 100%;
      background-color: $athens-gray;
      position: absolute;
    }
  }

  &__sub-labels {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin-top: 15px;

    .left, .right {
      .label {
        font-size: 14px;
        font-weight: 600;
        color: black;
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      .value.no-da {
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        color: $slate-grey
      }

      .value {
        margin-left: 22px;
        font-size: 16px;
        font-weight: normal;
        color: $navy;
        display: flex;
        flex-direction: row;

        .info-indicator {
          margin-left: 10px;
        }
      }

      .square-indicator {
        width: 14px;
        height: 14px;
        margin-right: 8px;
      }
    }


    .left .square-indicator {
      background-color: $grey-blue;
    }

    .right .square-indicator {
      background-color: $athens-gray;
    }
  }
}