@import "../colors";

.header-dashboard {
  background: $navy;
  flex: 1;
  padding: 20px;
}

.beneficiaries-dashboard {
  min-width: 280px;

  .header-dashboard-item__indicator {
    .bar-indicator-row {
      &__desc {
        width: 95px;
      }
    }
  }

  .small-indicator-label {

    .header-dashboard-item__indicator {
      .bar-indicator-row {
        &__desc {
          width: 85px;
        }
      }
    }
  }

  .country-breakdown-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    &__link-icon {
      margin-left: 2px;
    }

    .emergency-level {
      width: 20px;
      height: 20px;
      font-size: 12px;
    }

    &__label {
      flex: 1;
      margin: 0 5px;
      font-weight: 700;
      color: white;

      span {
        white-space: nowrap;
      }
    }

    &__value {
      min-width: 60px;
      text-align: right;
    }

    &__label:nth-child(1) {
      margin-left: 0;
    }
  }

  .country-breakdown-item:last-child{
    margin-bottom: 0;
  }

  .title__container {
    .breakdown:after {
      font-family: FontAwesome;
      content: "\f107";
      padding-left: 0.3em;
    }
  }
}

.header-tooltip-theme.tippy-tooltip {
  background-color: $bar-dark-blue;

  .arrow-regular {
    border-top: 7px solid $bar-dark-blue;
    border-right: 7px solid transparent;
    border-left: 7px solid transparent;
  }
}


.header-panel-dashboard, .header-dashboard {
  color: white;
  background: $navy;
  font-family: 'Open Sans', sans-serif;
  border-radius: 4px;

  .loading-container {
    display: flex;
    flex: 1;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  &__wrap {
    display: grid;
    grid-template-columns: 1fr 1fr 2fr;
    grid-gap: 1px;
    background-color: $b-grey;
  }

  &__rest {
    display: grid;
    grid-template-columns: 2fr 1.1fr;
    grid-gap: 1px;
  }


  .header-wrap {
    .total {
      color: $saffron;

      .value {
        color: $saffron;
        font-weight: bold;
      }
    }
  }

  &-item {

    &__list {
      display: grid;
      grid-gap: 10px;
    }


    &__indicator {
      &__label.bar-indicator-row__item-label {
        font-weight: 700;
      }

      .bar-indicator__value {
        color: #fff;
        font-size: 16px;
      }

      .bar-indicator-row {

        &__item {
          font-size: 16px;
        }

        &__desc {
          width: initial;
          min-width: 40px;
        }
      }

      .bar-indicator {

        .scale {
          background-color: $bar-dark-blue;
        }

        .scale:nth-child(2) {
          background-color: $bar-light-blue
        }

        .scale:nth-child(3) {
          background-color: $bar-light-blue-third
        }
      }
    }

    &__label {
      margin-bottom: 10px;
      color: $saffron;
      font-weight: 700;
      font-size: 16px;
    }
  }

  &__indicators {
    background-color: $navy;

    .title__container {
      height: 67px;
      padding-bottom: 12px;
      margin-bottom: 12px;
      border-bottom: 1px solid #ffffff20;
    }

    .column__title {
      font-size: 20px;
      font-weight: 600;
      color: $saffron;
      margin-bottom: 3px;
    }

    .column__subtitle {
      font-size: 16px;
    }

    .indicator-set {
      background-color: $navy;
    }
  }

  .right-indicators {
    display: grid;
    grid-template-rows: minmax(100px, 120px) 1fr;
    grid-gap: 1px;
    background-color: $b-grey;

    &__top {
      padding: 20px;
      background-color: $navy;
      min-height: 100px;
    }

    &__bottom {
      padding: 20px;
      background-color: $navy;
      min-height: 100px;

      .number-indicator {
        min-height: 85px;
      }

      .percent-indicators {
        &__label {
          font-size: 14px;
          font-weight: bold;
          margin-bottom: 10px;
        }
      }
    }
  }

  .vertical-indicators {
    padding: 20px;
    background-color: $navy;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .sub-title {
      font-size: 12px;
      font-weight: 600;
    }
  }

  .dark-no-data {
    background-color: $navy;
  }
}

.header-panel-dashboard.region {
  .net-funding-label {
    &__sub-title {
      font-weight: normal;
    }
  }
}

.header-panel-dashboard {
  &__wrap.country {
    grid-template-columns: minmax(500px, 2fr) 1fr 1fr;

    .header-dashboard-item__list {
      display: block;
    }

    .header-wrap {
      .header-wrap__indicators.loading {
        grid-template-columns: 1fr;
      }

      .header-wrap__indicators {
        grid-template-columns: 1fr 1fr;
        grid-gap: 1px;

        .header-list-indicators {
          padding-right: 10px;
        }

        .employees-indicators {
          padding-left: 10px;
          padding-top: 7px;
        }
      }
    }
  }
}

.header-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 2px;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 30px;
  height: 35px;
}

// specific media styles
@media only screen and (max-width: 1150px) {
  .header-panel-dashboard {
    &__wrap, &__wrap.country {
      grid-template-columns: 1fr;

      .beneficiaries-dashboard {
        grid-gap: 20px;
      }
    }
  }
}

@media only screen and (max-width: 1150px) and (min-width: 650px) {
  .header-panel-dashboard {
    .header-wrap .header-wrap__indicators {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 1px;

      .header-list-indicators {
        padding-right: 10px;
      }

      .employees-indicators {
        padding-top: 7px;
        padding-left: 10px;
      }
    }

    .beneficiaries-dashboard {
      grid-gap: 20px;
    }

    .header-dashboard-item__list {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: none;
    }

  }
}


@media only screen and (max-width: 650px) {
  .header-panel-dashboard {
    &__wrap {
      margin-left: 0;
    }

    &__wrap.country {

      .header-wrap {
        .header-wrap__indicators {
          grid-template-columns: 1fr;

          .header-list-indicators {
            padding-right: 0;
          }

          .employees-indicators {
            padding-left: 0;
            padding-top: 20px;
          }
        }
      }
    }
  }
}

