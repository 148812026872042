
.management-switchers {
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 20px;
  margin-top: 30px;
  font-size: 14px;

  &__elements {
    display: flex;
    flex-flow: row wrap;
    padding-bottom: 5px;

    .item-wrap {
      flex: inherit;
      margin-right: 40px;
    }
  }

  &__title {
    font-size: 14px;
    font-weight: 600;
    margin-right: 15px;
    padding-bottom: 5px;
  }

  .wfp--form-item.wfp--checkbox-wrapper {
    margin-bottom: 0;
  }

  .wfp--form-item.wfp--checkbox-wrapper:first-of-type {
    margin-top: 0;
  }
}
