
.ie-warring {

  // ie fix
  .wfp--modal-inner{
    justify-content: flex-start;
  }

  &__pre-title {
    font-size: 16px;
    margin-bottom: 5px;
  }

  &__title {
    font-size: 26px;
    font-weight: 600;
    margin-bottom: 30px;
  }

  &__links {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    margin-top: 30px;

    img {
      width: 50px;
      height: 50px;
      margin-right: 15px;
    }
  }
}