@import '../../colors';

.top-counties-indicator {
  display: flex;
  flex-direction: column;

  &__title {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 15px;
  }

  &__items {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  &__items .item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-height: 25px;

    .title {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0 10px 5px 0;

      .name {
        text-decoration: none;
        font-size: 12px;
        font-weight: normal;
        color: white;
        max-width: 190px;
        line-height: 1.3;
      }

      .crisis-level {
        margin-left: 5px;
        height: 16px;
        width: 30px;
        min-width: 30px;
        text-decoration: none;
        font-size: 10px;
        font-weight: bold;
        line-height: 1.9;
        border-radius: 14px;
        color: white;
        text-align: center;
        text-transform: capitalize;
        border-bottom: none;
      }

    }

    .value {
      font-size: 12px;
      font-weight: normal;
      color: white;
      align-self: flex-start;
    }
  }

  .info-indicator {
    padding-right: 5px;
    flex-shrink: 0;
  }
}

@media only screen and (max-width: 991px) {
  .top-counties-indicator {
    .info-indicator {
      margin-right: 0;
    }
  }
}
