@import "../../../colors";

.programme-tab-content {
  display: grid;
  grid-template-rows: 1fr;
  grid-gap: 20px;

  &__title {
    margin-top: 40px;
    font-size: 24px;
    font-weight: 600;
  }
}