@import "../../colors";


.emergency-list {
  display: flex;
  flex-direction: column;
  color: white;

  &__items.full-size {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  &__items {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 15px;

    .item {
      display: flex;
      flex-direction: row;
      align-items: center;

      .emergency-values {
        display: flex;
        flex-direction: column;
        margin-left: 15px;

        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;

        .value {
          font-weight: bold;
          cursor: pointer;
        }

        .value:after {
          font-family: FontAwesome;
          content: "\f107";
          padding-left: 0.3em;
        }

        .without-arrow {
          cursor: unset;
        }

        .without-arrow:after {
          display: none;
        }
      }
    }

    .item:last-child {
      padding-bottom: 0;
    }
  }

  .emergency-level {
    //background-color: $grey-blue;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.79;
    letter-spacing: normal;
    text-transform: capitalize;
  }

  .emergency-level.M {
    background-color: $nice-blue;
  }

  .emergency-level.L1 {
    background-color: $blue-grey;
  }

  .emergency-level.L2 {
    background-color: $orange;
  }

  .emergency-level.L3 {
    background-color: $cardinal;
  }

  .emergency-level.SU, .CA, .AR {
    img {
      width: 30px;
      height: 30px;
      object-fit: cover;
    }

    img.small{
      width: 20px;
      height: 20px;
    }

    //background-color: $night-shadz;
  }

  .emergency-level.CA {
    //background-color: $amaranth;
  }

  .emergency-level.AR {
    //background-color: $jaffa;
  }
}

.emergency-countries {
  padding: 5px 0 0 10px;

  .emergency-counties-list__item {
    font-weight: normal;
  }
}

.emergency-name {
  -webkit-font-smoothing: antialiased;
  font-size: 14px;
  font-weight: 600;

  a {
    &:hover {
      text-decoration: underline;
    }
  }

  span {
    margin: 5px 0;
  }

  .emergency-counties-list__item {
    font-size: 14px;
  }
}

.emergency-counties-list {
  &__title {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 15px;
  }

  &__item {
    font-size: 13px;
    font-weight: bold;
    max-width: 190px;
    //padding-bottom: 5px;
  }

  a {
    text-decoration: none;
  }
}
