.horizontal-indicator-multiple {
	width: 100%;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	letter-spacing: normal;
	line-height: 1;

	b {
		font-weight: 700;
	}

	&__main-content {
		flex-direction: row;
		display: flex;
		align-items: flex-end;
		padding-bottom: 5px;
	}

	&__values {
		align-self: flex-start;
		margin-right: 10px;

		.tooltip {
			position: relative;
			display: block !important;

			.value {
				border-bottom: 1px solid white;
			}
		}

		.value {
			font-size: 46px;

			.symbol {
				font-size: 25px;
			}
		}

		.currency {
			font-size: 14px;
		}
	}

	&__labels {
		width: auto !important;

		.label {
			font-size: 16px;
			line-height: 1.25;
			align-items: flex-end;
			display: inline-block;

			@media only screen and (min-width: 1184px) {
				.value {
					padding-right: 45px;
				}
			}

			.tooltip {
				position: relative;
			}
		}

		.label::first-letter {
			text-transform: uppercase;
		}

		.year {
			font-weight: 400;
			font-size: 15px;
			padding-bottom: 5px;
		}
	}

	&__beneficiaries {
		flex-basis: 100%;
		font-size: 15px;
		margin-top: 5px;
		display: inline;

		.tooltip {
			position: relative;

			.value {
				border-bottom: 1px solid white;
				display: inline;
			}
		}

		.value {
			margin-bottom: 5px;
		}

		.year {
			font-weight: 400;
			padding: 0 5px;
		}

		.label {
			font-weight: 400;
		}
	}
}

.tooltip-content-msg {
	line-height: 1.2;
}
