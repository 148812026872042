.header--primary {
    position: relative;
    @include media-query($md-screen) {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
    }
}

.header__mobile {
    position: relative;
    @include media-max($md-screen) {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-between;
    }
}

// TODO: Move to WFP UI
.wfp-header-int .header__content, .wfp-header-ext .header__content {
    box-shadow: none;
    @include media-max($md-screen) {
        position: relative;
        top: 0;
    }
    @include media-query($md-screen) {
        white-space: nowrap;
        .rc-menu-sub {
            white-space: normal;
        }
    }
}

// ToDo: Some kind of Print header
.wfp-header-int {
    max-height: inherit;
    @include print {
        //display: none;
        /* position: fixed;
         display: block;
         bottom: 0cm; */
        position: absolute;
        background: none;
        box-shadow: none;
        display: none;
        .header--logo {
            color: $brand-01;
        }
        .header__title {
            margin: 0; //position: absolute;
            //right: 0;
        }
        .header__mobile__button,
        .content {
            display: none;
        }
    }
}

.header__title {
    margin: 0;
    font-size: 1.5rem;
    white-space: nowrap;
    line-height: 1;
    padding: .7em 0.7em;
    @include media-query($md-screen) {
        font-size: 1.3rem;
        margin: 0.6rem 0.4rem;
        padding: .7em 0;
    }
    @include media-query($md-screen) {
        margin: 0.8rem 0;
    }
    span {
        //color: darken($accent, 5);
        display: inline-block;
        margin: 0 0.05em;
        font-size: 1em;
        font-weight: 100;
        transition: all 0.2s;
        vertical-align: bottom;
    }
    &:hover {
         span span {
            transform: rotate(-4deg);
        }
    }
}

.header__mobile__button {
    float: right;
    font-weight: bold;
    color: $white !important;
    background-color: $blue-darker;
    border: none;
    cursor: pointer;
    border-radius: 3px;
    font-size: 1rem;
    padding: 0.5rem 1rem;
    margin-right: 0.5em;
    @include media-query($md-screen) {
        display: none;
    }
    &:focus {
        outline: 0;
    }
    .open & {
        background-color: $blue-darkest;
    }
}


.header__search {
    position: relative;
    display: inline-block !important;
    @include media-query($md-screen) {
        margin-right: 0 !important;
    }
    &:after {
        display: none !important;
    }
    @include media-max($md-screen) {
        order: -2;
        //border-bottom: 1px solid $blue;
        padding: 0.7rem !important;
        width: 100%;
        .react-autosuggest__input {
            border: 0 !important;
        }
        float: right;
    }
    @include media-query( $md-screen) {
        margin-left: 0.5rem !important;
    }
}


.header__user {
    @include media-max($md-screen) {
        order: -1;
    }
    /* @include media-query( $md-screen) {
        margin-left: 0.3rem !important;
    } */
    span {
        vertical-align: middle;
    }
    .avatar {
        display: none;
        height: 33px;
        width: 33px;
        background: $white;
        font-size: 0.01em;
        border-radius: 100px;
        box-shadow: 2px 2px 0 rgba($black,0.05);
        border: 1px solid $white;
        display: inline-block;
        margin-top: -2em;
        margin-bottom: -2em;
        margin-right: 0.5rem;
        @include media-query($md-screen) {
            margin-top: -2.2em;
        }
    }
    margin-right: 0 !important;
    .header__user__name__text {
        display: inline-block;
        overflow: hidden;
        white-space: nowrap;
        max-width: 80%;
        text-overflow: ellipsis;
        @include media-query($md-screen) {
            max-width: 140px;
            display: none;
        }
        @include media-max($md-screen) {
            width: 100%;
        }
    }
}

.block--mega-menu-block {
    @include media-query($md-screen) {
        margin-right: 1.5em;
        width: 100%;
    }
}

.header__content {
    @include media-query($md-screen) {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: flex-end;
        // Height Bugfix for Safari
        margin: 0;
    }
    @include media-max($md-screen) {
        background: $blue-darker;
        position: relative;
        z-index: 10000;
        display: block;
        height: auto;
        width: 100%;
        max-height: 0;
        overflow: hidden; //animation: close 2000ms ease-out 0s;
        transition: max-height 0.5s ease-out;
        display: flex;
        flex-direction: column;
        .animating & {
            overflow: hidden;
        }
        .open & {
            overflow: visible;
            /*display: block;
            height: auto;*/
            max-height: 800px; //animation: open 2000ms ease-out 0s 1s;
        }
    }
}

.content {
    @include media-max($md-screen) {
        width: 100%;
    }
}

.header__content {
    &:focus {outline:0;}
    .menu--link {
        color: $white;
        border-bottom: 0;
        &:visited {
            color: $white;
        }
        padding: 1em 0.8em;
        @include media-query($md-screen) {
            margin: 0 .4em;
            padding: .2em 0.5em;
        }
        @include media-query($lg-screen) {
            margin: 0 .7em;
            padding: .2em 0.5em;
        }
        font-size: 1em;
        cursor: pointer;
        &.active {
            border-bottom: none !important;
        }
        &.is-active {
            border-bottom: 1px solid $white;
        }
        position: relative;
        font-weight: bold!important;
        @include media-max($md-screen) {
            color: $white;
            background: $blue-darker;
            &:active {
                background: $blue-darkest;
            }
            border-top: 1px solid $blue;
            display: block;
            width: 100%;
            position: relative;
            /*&:not(:only-child) {*/
                &:after {
                    content: '\203A';
                    transform: rotate(90deg);
                    display: block;
                    position: absolute;
                    right: 0.3em;
                    top: 0;
                    background-image: none;
                    font-size: 1.7em;
                    margin: 0.4em;
                }
            /*}*/
            .rc-menu-submenu-active & {
                &:after {
                    display: none;
                }
            }
        }
        text-transform: none;
        @include media-query($md-screen) {
            &:after {
                content: "";
                position: absolute;
                left: 0;
                bottom: 0em;
                width: 100%;
                height: 1px;
                background: $white;
                opacity: 0;
                transform: scaleX(0.5);
                transition: all 0.1s;
            }
            &:hover {
                border-color: transparent;
                /* &:after {
                    opacity: 0.3;
                    transform: scaleX(0.8);
                } */
            }
        }
        &.active {
            @include media-query($lg-screen) {
                border-bottom: none;
                &:after {
                    opacity: 1;
                    transform: scaleX(1); //background: $blue-darkest;
                }
            }
        }
    }
    .menu--link--mobile {
        @include media-query($lg-screen) {
            display: none;
        }
    }
    .menu--link--desktop {
        @include media-max($lg-screen) {
            display: none;
        }
    }
}

