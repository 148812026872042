@import "../colors";

.pie-chart-tooltip {
  position: absolute;
  background: $navy;
  padding: 7px 10px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;

  span {
    white-space: nowrap;
  }

  &__label {
    color: white;
    font-weight: 600;
    font-size: 14px;
    margin-right: 20px;
  }

  &__value {
    color: white;
    font-size: 14px;
  }

  &__caret {
    content: '';
    position: absolute;
    background: $navy;
    width: 7px;
    bottom: -3.5px;
    margin: 0 auto;
    height: 7px;
    transform: rotate(45deg);
  }
}
