@import "../../colors";

.bar-indicator-column {
  margin: 5px 0 18px 0;

  .bar-indicator__scale-wrap {
    position: relative;
  }

  .bar-indicator__scale-container {
    border-radius: 0;
    overflow: visible;
    align-items: center;
    margin-right: 0;
  }

  &__label {
    margin-top: 5px;
    font-size: 14px;
    font-weight: 600;
  }

  .current-value {
    font-weight: 600;
    text-decoration: underline;
    color: var(--interactive-01);
  }

  .scale.single {
    border-radius: 0 10px 10px 0;
  }
}

.tooltip-context {


}

