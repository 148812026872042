@import '../../../colors';

.line-rectangle-indicators {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 20px;
  justify-content: center;
  align-items: center;
  min-height: 200px;

  &__indicator {
    background-color: white;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: solid 2px $geyser;
    border-radius: 4px;

    .currency {
      font-size: 16px;
      line-height: 1.31;
    }

    .value {
      font-size: 35px;
      font-weight: 300;
      line-height: 0.6;
      margin-top: 10px;
      margin-bottom: 20px;
    }

    .label {
      font-size: 16px;
      font-weight: 600;
      line-height: 1.31;
    }
  }
}