@import "../colors";

.glossary-menu {
  .wfp--sub-navigation__title {
    display: flex;
  }
}

.custom-menu {
  .menu-list__title-container {
    display: flex;

    .info-indicator {
      margin-left: 5px;
    }
  }

  .wfp--sub-navigation__content .wfp--sub-navigation__group {
    .grouped-items {
      -webkit-column-count: 4;
      -moz-column-count: 4;
      column-count: 4;
    }
  }

  .wfp--sub-navigation__list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    grid-gap: 10px;
  }

  .wfp--sub-navigation__title {
    display: flex;

    .info-indicator {
      margin-left: 5px;
      margin-top: 3px;
    }
  }

  .wfp--sub-navigation__item {
    font-size: 14px;

    a {
      font-weight: normal;
      text-decoration: none;
    }
  }

  .region__subtitle {
    margin-bottom: 5px;

    a {
      font-size: 15px;
      text-decoration: none;
    }
  }
}

.custom-menu,
.glossary-menu {
  .wfp--sub-navigation__filter {
    flex-flow: wrap;
    width: initial;

    .headingwrap__center {
    }
  }
}

@media screen and (max-width: 875px) and (min-width: 768px) {
  .wfp--main-navigation__logo a {
    font-size: 14px !important;
  }

  .wfp--main-navigation__trigger a {
    font-size: 14px;
  }
}

@media screen and (max-width: 600px) {
  .custom-menu,
  .glossary-menu {
    .wfp--sub-navigation__filter {
      .headingwrap__center.order {
        display: none;
      }
    }
  }

  .custom-menu {
    .wfp--sub-navigation__content .wfp--sub-navigation__group {
      .grouped-items {
        -webkit-column-count: 2;
        -moz-column-count: 2;
        column-count: 2;
      }
    }
  }
}
