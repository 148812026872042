/**
 * Units
 *
 * All units in /helpers/valueHelpers.js
 * get a corresponding class
 */

/* M USD Element */

.unit {
  white-space: nowrap;
  &:after, .unit__small {
    font-size: 0.7em;
    font-style: italic;
    margin-left: 0.3em;
    opacity: 0.7;
  }
}

.unit--hide, .unit--hide .unit {
  &:after, tspan:last-child {
    display: none;
  }
}

.unit--light {
  &:after {
    font-weight: 300;
    margin-left: 0.2em;
    opacity: 1;
  }
}

.unit-text {
  font-weight: 300;
  margin-left: 0.2em;
  opacity: 1;
  font-size: 0.7em;
  font-style: italic;
}

.littleusd {
  &:after {
    content: "USD";
  }
}

.none {
  white-space: normal;
}

.musd {
  &:after {
    content: "M USD";
  }
}

.tusd {
  &:after {
    content: "k USD";
  }
}

.usd {
  &:after {
    content: "M USD";
  }
}

.busd {
  &:after {
    content: "B USD";
  }
}

.mt {
  &:after {
    content: "MT";
  }
}

.people, .mpeople {
  &:after {
    content: "M People";
  }
}

.million {
  &:after {
    content: "M";
  }
}

.partners {
  &:after {
    content: "Partners";
  }
}

.countries {
  &:after {
    content: "Countries";
  }
}

.percentage, .simplepercentage {
  &:after {
    content: "%";
  }
}

.mbeneficiaries {
  &:after {
    content: "M Beneficiaries";
  }
}

.beneficiaries,
.beneficiaries--text {
  &:after {
    content: "Beneficiaries";
  }
}

.months {
  &:after {
    content: "Months";
  }
}

.trips {
  &:after {
    content: "Trips";
  }
}

.svg-unit {
  tspan:last-child {
    font-size: 6px !important;
    font-style: italic;
    opacity: 0.6;
  }
}