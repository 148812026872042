.wfp-secondaryheader {
	position: relative;
	@include media-max($md-screen) {
		padding: 1rem 0.2rem 1.4rem;
	}
	@include print {
		padding-top: 0;
	}
	padding: 1.8rem 0 2rem 0;
	//box-shadow: 0px 2px 1px rgba($blue, 0.1) !important;
	@extend .main-shadow;
	>div {
		display: flex;
		align-items: center;
	}
}

.secondaryheader__title {
	margin-top: 0.3em;
	margin-bottom: 0;
	font-size: 1.2rem;
	@include print {
		font-size: 1.8rem;
	}
}

.secondaryheader__title__icon {
    height: 0.7em;
    width: auto;
    display: inline;
    padding-right: 0.2em;
    vertical-align: baseline;
}

/* Emergency Level Icon with link to OPweb */
.emergencylevel {
	display: inline-flex;
	/*background: none;
	align-items: center;
	margin-right: 0.15em;
	margin-top: -0.25em;
	position: relative;
	top: -0.15em;
	border-radius: 3px;*/
	.emergencylevel__icon {
	    display: inline-block;
	    ///color: #FFF !important;
	    //font-size: 1.2em;
	    height: 100%;
	    //padding: 0.3em;
	    margin-right: 0.2em;
	    //border-radius: 3px;
	}
    &.l0 {
    	.emergencylevel__icon {
	        color: $brand-01;
	    }
    }
    &.l2 {
    	.emergencylevel__icon {
	        color: $orange;
	    }
    }
    &.l3 {
    	.emergencylevel__icon {
	        color: $red;
	    }
    }
    &.other {
    	.emergencylevel__icon {
			color: $brand-01;
    	}
    }
}

.emergencylevel__text {
	font-size: 1em;
	color: $text-color;
	font-weight: 800;
	letter-spacing: 0;
	margin: 0 0.7em;
}

.refresh_menu_dropdown {
	display: none;
}


.header_right {
	position: absolute;
	top: 2rem;
	right: 0;
	//TODO: Make this nicer
	/*.refresh_menu_span {
		i {
			position: relative;
			top: 5px;
		}
	} */
}

/* TODO: Remove fixes once actual behaviour is clear */

.wfp-secondarynav__item{
	&:first-child {
		margin-left: -1em;
	}
	a {
		line-height: 1.7rem;
		padding: 0 1rem;
		&.active {
			&:after {
				height: 2px;
			}
		}
	}

}

.wfp-secondaryheader__navigation {
	flex-grow: 1;
	margin-left: 0.5em;
}

.wfp-secondarynav__wrapper {
	display: none;
	box-shadow: none;
	overflow: visible;
	font-size: 0.8em;
	&.wfp-secondarynav__wrapper--open {
		display: inline-block;
		position: absolute;
		margin-top: 0.2em;
		margin-left: 0.3em;
		z-index: 100;
		padding-right: 3em;
		background: linear-gradient(to right, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 92%,rgba(255,255,255,0) 100%);
	}
}

.wfp-secondarynav__director {
	&:after {
		font-family: FontAwesome;
	    content: "\f107";
	    padding-left: 0.3em;
	}
}

.wfp-secondaryheader__breadcrumb {
	position: relative;
	.wfp-breadcrumbs-light .breadcrumbs--link.home {
		&:before {
			width: 20px;
			height: 20px;
			background-size: 100% auto;
		}
	}
}