@import "../colors";

.donors-bars {

  &__forecasted, &__confirmed {
    flex-grow: 1;
    background-color: white;
  }

  &__confirmed {
    padding-right: 25px;
  }

  &__forecasted {
    padding-left: 25px;
  }

  &__container {
    height: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    grid-gap: 2px;
    background-color: $light-grey;

    .bar-indicator-row__desc {
      width: 200px;
    }

    .no-data {
      background-color: white;
    }

    .indicators-row__subtitle {
      .tooltip {
        position: relative;
        width: auto;
        color: var(--interactive-01);
        text-decoration: underline;
      }
    }
  }
}

.donors-legend__container {
  margin-top: 20px;

  .legend .mark-wrap {
    margin-right: 12px;
  }
}

@media (max-width: 876px) {
  .donors-bars {
    &__confirmed {
      padding-right: 0;
      padding-bottom: 25px;
    }

    &__forecasted {
      padding-left: 0;
      padding-top: 25px;
    }
  }
}

@media (max-width: 500px) {
  .donors-bars {
    &__container {
      grid-template-columns: repeat(auto-fit, minmax(246px, 1fr));
    }
  }
}

@media (max-width: 351px) {
  .donors-bars {

    &__container {
      .bar-indicator-row__desc {
        width: 145px;
      }
    }
  }
}
