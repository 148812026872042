@import "../../colors";

.indicator {
  display: flex;

  &__value {
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    white-space: nowrap;
    font-size: 46px;

    .symbol-after {
      font-size: 25px;
    }

    .symbol-before{
      font-size: 14px;
    }
  }

  &__labels.center {
    align-self: center;
  }

  &__label {
    font-size: 16px;
    text-align: left;
  }

  &__label.bold{
    font-weight: bold;
  }

  &__second-label {
    font-size: 12px;
    font-weight: 600;
    line-height: 1.67;
    text-align: left;
  }

  &__gray-label {
    font-size: 12px;
    font-weight: 600;
    line-height: 1.67;
    text-align: left;
    color: white;
  }
}

.indicator.horizontal {
  flex-direction: row;

  .indicator__value {
    padding-right: 10px;
  }
}

.indicator.vertical {
  flex-direction: column;
}

.indicator.center {
  align-self: center;
  justify-self: center;
  text-align: center;
}

.indicator.hide {
  display: none;
}
