.share__preview {
	border: 3px solid $brand-01;
	width: 100%;
	min-height: 300px;
	position: relative;
	padding: 0.3em;
	iframe {
		border: none;
		display: block;
		margin: 0;
		padding: 0;
	}
	&img {
		height: auto;
	}
    box-shadow: rgba(desaturate($blue, 70%), 0.2) 0 3px 12px,
            rgba(desaturate($blue, 70%), 0.2) 0 32px 78px;
}

.wfp-form--stacked .share__code {
	font-size: 0.8em;
	font-family: "Courier New", Courier, monospace;
	font-weight: normal;
}

