// Project Variables
// Project: WFP UI

$sans-serif-stack:    "lato", -apple-system, BlinkMacSystemFont, system, sans-serif;
$serif-stack:         "aleo", -apple-system, BlinkMacSystemFont, system, serif;
$mono-stack:          monospace;
$base-font-size:      1rem; // 16px
$base-line-height:    1.500; // 21px
$small-font-size:     $base-font-size * 0.875; // 14px

$spacing-unit:        0.25rem;

$primary:             #0F58A5;
$accent:              #174E89;

// Greys gradation
$grey:                #8c8c8c;
$grey-darkest:        darken($grey, 42%);
$grey-darker:         darken($grey, 36%);
$grey-dark:           darken($grey, 18%);
$grey-light:          lighten($grey, 18%);
$grey-lighter:        lighten($grey, 36%);
$grey-lightest:       lighten($grey, 42%);

// Blue gradation
$blue:                lighten($primary, 9%);
$blue-darkest:        darken($blue, 36%);
$blue-darker:         darken($blue, 18%);
$blue-dark:           darken($blue, 9%);
$blue-light:          lighten($blue, 25%);
$blue-lighter:        lighten($blue, 38%);
$blue-lightest:       lighten($blue, 52%);

$brand:               darken($primary, 12%);

$grey-contrast:       #ededed;

$black:               #000000;
$white:               #ffffff;
$red:                 desaturate(darken(#e6494a, 5%), 15%);
$orange:              #ffc759;
$yellow:              #fcdc5d;
$green:               #00a878;

$text-color:          transparentize($grey-darkest, 0.1) !default;
$background-color:    transparentize($white, 0.1) !default;

// Width of the content area
$content-width:       1000px !default;
$max-content-width:   1200px !default;

$sm-screen:           500px;
$md-screen:           768px;
$lg-screen:           992px;
$xl-screen:           1200px;

$mqs: (
  sm: 35.5em,
  md: 48em,
  lg: 64em,
  xl: 80em
);

$spacing: (
  1: 1rem,
  2: 2rem,
  3: 4rem,
  4: 6rem,
  5: 8rem,
  6: 10rem,
  7: 12rem,
  8: 16rem,
  9: 20rem,
  10: 24rem,
  11: 32rem,
  12: 48rem,
  13: 56rem,
  14: 64rem,
  15: 96rem,
  16: 100%
);
