// pages styles
@import 'pages/main-dashboard';
// panels styles
@import 'panels/panel';
@import 'panels/header-dashboard';
@import 'panels/header-global-dashboard';
@import 'panels/header-population';
@import 'panels/chart-indicators-panel';
@import 'panels/performance-panel';
@import 'panels/implementation-panel';
@import 'panels/header-general-panel';
@import 'panels/strategic-results-panel';
@import 'panels/sdg-targets-panel.scss';
@import 'panels/strategic-outcome-categories-panel.scss';
@import 'panels/strategic-goal-panel';
@import 'panels/strategic-outcome-panel';
@import 'panels/adjusted-beneficiaries-panel';
@import 'panels/kpis';
@import 'panels/header-bottom-indicators';
@import 'panels/header-info';
@import 'panels/resources-panel';
@import 'panels/donors-panel';
@import 'panels/focus-areas-panel';
@import 'panels/food-distribution-panel';
@import 'panels/partners-panel';
@import 'panels/beneficiaries-panel';
@import 'panels/top-countries-panel';
@import 'panels/regional-bureas-panel';
@import 'panels/output-indicators-panel';
@import 'panels/local-economies-panel';
@import 'panels/projected-requirements';

// components styles
@import 'components/indicators/indicator';
@import 'components/indicators/director-indicator';
@import 'components/indicators/header-list-indicators';
@import 'components/indicators/percent-progress-indicator';
@import 'components/indicators/info-indicator';
@import 'components/indicators/excel-export';
@import 'components/indicators/wasting-indicators';
@import 'components/indicators/top-countries';
@import 'components/indicators/one-line-indicator';
@import 'components/indicators/bar-indicator';
@import 'components/indicators/bar-indicator-column';
@import 'components/indicators/big-percent-indicator';
@import 'components/indicators/horizontal-indicator';
@import 'components/indicators/horizontal-indicator-multiple';
@import 'components/indicators/employees-indicators';
@import 'components/indicators/offices-indicators';
@import 'components/indicators/countries-indicators';
@import 'components/indicators/emergency-list';
@import 'components/indicators/info-source';
@import 'components/indicators/table-indicators';
@import 'components/card';
@import 'components/charts/legend';
@import 'components/charts/doughnut-charts';
@import 'components/charts/doughnut-legend';
@import 'components/charts/stacked-bar-chart-legend';
@import 'components/charts/radar-chart';
@import 'components/charts/bar-chart';
@import 'components/charts/horizontal-bar-chart.scss';
@import 'components/charts/area-chart.scss';
@import 'components/charts/dash-legend.scss';
@import 'components/about';
@import 'components/crisis-level';
@import 'components/switcher';
@import 'components/environment-indicator';
@import 'components/browser-support-indicator';
@import 'components/menu';
@import 'components/switch';
@import 'components/hamburger-menu';
@import 'components/pie-chart-tooltip';
@import 'components/label-tooltip';
// tabs components
@import 'components/tabs/global-tabs';
@import 'components/tabs/programme/programme-content';
@import 'components/tabs/content-header';
@import 'components/tabs/programme/line-rectangle-indicators';
@import 'components/tabs/programme/strategic-outcome-activity';
@import 'components/tabs/management/management-switchers';
// extensions styles
@import 'extensions/tippy';

// other pages
@import 'mobile';
@import 'error-page';

// some general styles

.content {
	padding: 20px 0;
	font-family: 'Open Sans', sans-serif;
	color: $navy;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
}

.content:empty {
	padding: 0 !important;
}

.bold {
	font-weight: bold;
}

.white-bg {
	background-color: white;
}

.wfp--tabs a:focus {
	box-shadow: none;
	-webkit-box-shadow: none;
}

.wfp--module {
	padding: 0;

	&__inner {
		.wfp--module__title {
			font-size: 16px;
		}
	}
}

.wfp-secondarynav__director {
	max-width: 200px;
}

.wfp--module .wfp--module__content {
}

.wfp--main-navigation__logo a {
	font-size: 20px;
	font-weight: 700;
}

.wfp--modal-content {
	p {
		margin-block-start: 1em;
		margin-block-end: 1em;
	}

	ul {
		display: block;
		list-style-type: disc;
		margin-block-start: 1em;
		margin-block-end: 1em;
		margin-inline-start: 0px;
		margin-inline-end: 0px;
		padding-inline-start: 40px;
	}

	ol {
		display: block;
		list-style-type: decimal;
		margin-block-start: 1em;
		margin-block-end: 1em;
		margin-inline-start: 0px;
		margin-inline-end: 0px;
		padding-inline-start: 40px;
	}

	li {
		line-height: 1.4;
	}
}

.wfp--tabs {
	&__nav-item {
		a {
			padding: 0.82rem 0.5rem;
			font-size: 20px;
			color: $navy;
			font-weight: 400;
		}
	}

	&__nav-item--selected {
		a {
			color: $nice-blue;
		}
	}

	&__nav-item--disabled {
		a {
			color: white;
			cursor: default;
		}
	}

	&__nav__bar {
		bottom: 1.8px;
	}
}

.wfp--secondary-navigation {
	&__list {
		min-width: 100%; // for tabs fix
	}
}

.initializing-icon {
	justify-content: center;
	display: flex;
}

.google-play-link {
	img {
		height: 50px;
	}
}

.tooltip-info {
	ul {
		display: block;
		list-style-type: disc;
		margin-block-start: 1em;
		margin-block-end: 1em;
		margin-inline-start: 0;
		margin-inline-end: 0;
		padding-inline-start: 40px;
	}
}

.wfp--user__title {
	display: none;
}

.wfp--tabs__nav {
	align-items: flex-end;
	overflow: hidden;
}

.wfp--banner-navigation {
	.wfp--wrapper {
		max-width: 1200px;
	}
}

.react-autosuggest__suggestions-container
	li
	> .suggestion__content-empty:hover {
	background: #fff;
}

.wfp--main-navigation__user {
	.menu__title {
		max-width: 220px;
		word-break: break-word;
		font-weight: 700;
	}
	.wfp--main-navigation__sub {
		min-width: 350px;

		.wfp-btn:hover {
			border-width: 2px;
			border-style: solid;
			border-color: transparent;
		}

		.wfp--sub-navigation__group__title {
			font-size: 16px;
			font-weight: 700;
		}

		.wfp--link {
			font-size: 16px;
			font-weight: 400;
		}

		.wfp--sub-navigation__link {
			flex-grow: 0;
		}
	}
}

.arrow:after {
	font-family: FontAwesome;
	content: '\f107';
	padding-left: 0.3em;
}

.pdf-source-info {
	margin-top: 20px;
}

@media screen and (min-width: 992px) {
	.wfp--main-navigation__user .wfp--main-navigation__sub--open {
		left: inherit;
		right: 0;
		width: 25%;
		min-width: 350px;
	}
}

@media screen and (max-width: 500px) {
	.wfp--tabs__nav-item a {
		font-size: 14px;
	}
}

button {
	background: transparent;
	box-shadow: 0 0 0 transparent;
	border: 0 solid transparent;
	text-shadow: 0 0 0 transparent;
	font-family: 'Open Sans', sans-serif;
}

button:hover {
	background: transparent;
	box-shadow: 0 0 0 transparent;
	border: 0 solid transparent;
	text-shadow: 0 0 0 transparent;
}

button:active {
	outline: none;
	border: none;
}

button:focus {
	outline: 0;
}

.navy-bg {
	background-color: $navy;
}
