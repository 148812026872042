@import "../../colors";

.countries-indicators {
  margin-top: 15px;

  &__items {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  &__item {
    line-height: 25px;
    padding-right: 5px;
  }

  .wfp--link {
    margin-top: 5px;
  }

  &__list-item {
    font-size: 14px;
  }

  .wfp-tooltip {
    flex: 0 1 100%;
  }
}
