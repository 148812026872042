.horizontal-indicator {
	width: 100%;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	letter-spacing: normal;
	line-height: 1;

	&__main-content {
		display: flex;
		flex-direction: row;
		align-items: flex-end;
	}

	&__extra-content {
		font-size: 15px;
		font-weight: 400;
		margin-top: 6px;

		.item {
			display: flex;
			flex-direction: row;
			flex-basis: 100%;
		}

		.value {
			font-weight: 700;
			margin-bottom: 5px;
			margin-right: 10px;
		}
	}

	&__values {
		margin-right: 10px;

		.value {
			font-size: 46px;

			.symbol {
				font-size: 25px;
			}

			.disclaimer-icon {
				font-size: 16px;
				position: absolute;
				bottom: 30px;
			}
		}

		.currency {
			font-size: 14px;
		}
	}

	b {
		font-weight: 700;
	}

	&__labels {
		.label {
			font-size: 16px;
			line-height: 1.25;
			display: flex;
			align-items: flex-end;
		}

		.sub-label-big,
		.sub-label {
			font-weight: 400;
			font-size: 15px;
			white-space: nowrap;
			padding-bottom: 5px;
		}

		@media only screen and (min-width: 1184px) {
			.sub-label-big {
				margin-left: -47px;
			}
		}
	}
}
