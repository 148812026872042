@import '../colors';

.header-panel-dashboard.global {
	.indicator-set {
		display: flex;
		flex-direction: column;

		.indicator.vertical {
			min-height: 75px;
		}

		&__grouped {
			margin-top: 40px;

			.grouped-label {
				font-size: 14px;
				font-weight: bold;
				margin-bottom: 10px;

				span {
					text-decoration: underline;
				}
			}

			.grouped-label.arrow-down:after {
				font-family: 'FontAwesome', sans-serif;
				content: '\f107';
				padding: 5px;
				color: white;
			}

			.grouped-indicators {
				&__item {
					display: flex;
					flex-direction: row;
					margin-bottom: 5px;
					justify-content: space-between;

					.label {
						font-size: 12px;
						font-weight: bold;
						justify-self: center;
						align-self: center;
					}

					.indicator {
						width: 60%;
					}

					.progress-symbol {
						color: white;
					}
				}
			}
		}

		.about-indicator {
			flex-grow: 1;
		}
	}
}

.indicator-set-switcher {
	cursor: pointer;
	width: 100%;
}

.indicator-set-options {
	display: flex;
	flex-direction: column;

	div {
		cursor: pointer;
		border-bottom: 1px solid $athens-gray;
		color: $water-blue;
		padding: 10px 0;
	}
}

.emergency-list-items {
	display: -webkit-inline-box;
}

.emergency-list-column {
	display: flex;
	flex-direction: column;
	row-gap: 4px;
	padding-right: 15px;
}

// specific media styles
@media only screen and (max-width: 888px) {
	.header-panel-dashboard.global {
		.indicator-set {
			&__grouped {
				margin-top: 0;
			}
		}
	}
}
