/**
 * Vertical Tabs
 *
 * Vertical Tabs to switch between different content
 * found on some charts on the country pages where
 * there is a selection
 */

.wfp-verticaltabs, .area-button-container {
  input {
    display: none;
  }
  margin: 0;
  padding: 0;
  position: relative;
  z-index: 10;
  font-family: $sans-serif-stack;

  &__item {
    direction: ltr;
    min-height: 20px;
    position: relative;
    background: $white;
    border-bottom: 1px solid $grey-lighter;
    display: flex;
    align-items: center;
    padding: 0.5em 1.5em;
    cursor: pointer;
    text-align: left;
    margin: 0;
    @include print {
      display: none;
    }
    &:hover {
      background-color: $blue-lightest;
    }
    &:after {
      position: absolute;
      top: 50%;
      right: -3px;
      content: "";
      margin-top: -8px;
      width: 16px;
      height: 16px;
      background: $brand-01;
      z-index: -1;
      transform: translateX(-30px) rotate(45deg);
      transition: all 0.2s;
    }
    &.active {
      background: $brand-01;
      color: $white;
      border-color: $brand-01;
      @include print {
        display: block;
        color: $black;
        background-color: $white;
        border: none;
        &:after {
          display: none;
        }
      }
      &:after {
        transform: translateX(0) rotate(45deg);
      }
    }
  }

}

.area-button-container {
  overflow: auto;
  overflow-x: hidden;
  padding-right: 0em;
  max-height: 550px;
  direction: rtl;
  &::-webkit-scrollbar {
    width: 0.5em;
  }

  &::-webkit-scrollbar-track {
  }

  &::-webkit-scrollbar-thumb {
    background-color: $grey;
    outline: 1px solid slategrey;
  }
}