@import '../../colors';

.wasting-indicators {
  display: flex;
  flex-direction: row;
  align-items: center;

  &__switcher {
    cursor: pointer;
    width: 100%;
  }

  &__options {
    display: flex;
    flex-direction: column;

    div {
      cursor: pointer;
      border-bottom: 1px solid $athens-gray;
      color: $water-blue;
      padding: 10px 0;
    }
  }

  .percent-progress-indicator__label > .left:after {
    font-family: 'FontAwesome', sans-serif;
    content: "\f107";
    padding: 5px;
    color: white;
  }
}
