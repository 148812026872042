$lineHeight: 50px;
$lineHeightSmall: 40px;

.financechart--section {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding-top: 5px;
}

.financechart--sectiontitle {
  line-height: $lineHeight;
  width: 100%;
  text-overflow: ellipsis;
  .financechart--small & {
    line-height: $lineHeightSmall;
  }
}

.financechart--progress {
  height: $lineHeight;
  width: 100%;
  display: flex;
  flex-direction: row;
  background: $grey-lighter;
  .financechart--small & {
    height: $lineHeightSmall;
  }
}

.financechart--valuebar {
  background: none;
}

.financechart--progressbar {
  height: $lineHeight;
  transition: all 0.2s;
  border-right: 2px solid $white;
  .financechart--small & {
    height: $lineHeightSmall;
  }
  &:hover {
    //box-shadow: 0px 3px 10px rgba($black,0.3);
  }
}

.financechart--value {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;

  span {
    padding: 0 5px;
    color: $white;
    font-size: 1.2em;
  }
}

.narrow .financechart--value {
  color: $black;
}

.financechart--secondary {
  color: $black;
}

.narrow .financechart--value {
  left: 0;
}

.financechart--secondaryvalue {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  color: $white;
  padding-top: 3px;

  span {
    font-size: 1.2em;
    padding: 0 5px;
  }

  small {
    font-size: 12px;
    padding: 0 5px;
  }
}

.narrow {
  .financechart--secondary {
    &:before {
      margin-top: $lineHeight / 4;
      content: "";
      width: 120px;
    }
  }
}

.financechart__title__inner {
  margin-right: 0.3em;
}

.category-text {
  min-height: 40px;
}

@media only screen and (min-width: 500px) {
  .financechart--sectiontitle {
    width: 25%;
  }
  .financechart--progress {
    width: 75%;
  }
}