@keyframes wfp-modal-in {
    from {transform: scale(0.8); opacity: 0;}
    to {transform: scale(1); opacity: 1;}
}

@keyframes wfp-modal-out {
    from {transform: scale(1); opacity: 1;}
    to {transform: scale(0.8); opacity: 0;}
}


@keyframes wfp-modal__overlay-in {
    from {background: rgba($black, 0)}
    to {background: rgba($black, 0.5)}
}

@keyframes wfp-modal__overlay-out {
    from {background: rgba($black, 0.5)}
    to {background: rgba($black, 0)}
}


.wfp-modal__overlay {
    background: rgba($black, 0.5);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10000;
    display: flex;
  	justify-content: center;
  	align-items: center;
    animation: wfp-modal__overlay-in 0.2s forwards;
}

.modal__overlay__before--close {
    animation: wfp-modal__overlay-out 0.1s forwards;
}

.wfp-modal {
    width: 100%;
    max-width: 900px;
    max-height: 100%;
    margin-top: 0.5rem;
    position: relative;
    background: $white;
    box-shadow: rgba($black, 0.2) 0 3px 12px,
    			rgba($black, 0.2) 0 32px 78px;
    WebkitOverflowScrolling: touch;
    borderRadius: 4px;
    outline: none;
    animation: wfp-modal-in 0.05s forwards;
}


.wfp-modal__before--close {
    animation: wfp-modal-out 0.1s forwards;
}

.wfp-modal__content {
	padding: 2rem;
	padding-top: 0;
	width: 100%;
}

.wfp-modal__content--with-padding {
    padding-top: 2rem;
}

.wfp-modal__inner {
    overflow: auto;
    width: 100%;
    height: 100%;
    max-height: 85vh;
    background: $white;
}

.wfp-modal__inner--scrollable {
    @extend .wfp-modal__inner;
    padding: 1em;
}

.wfp-modal__close {
	background: none;
	border: none;
	position: absolute;
	top: -1.7em;
	right: 0.3rem;
    user-select: none;
    @include media-query($md-screen) {
        right: 0;
    }
	cursor: pointer;
	font-size: 1.1rem;
	z-index: 10000;
	color: $white;
    line-height: 1.5rem;
    padding: 0;
	padding-right: 1em;
	//text-shadow: 1px 2px 20px $black;
	&:after, &:before {
		position: absolute;
		top: 0.3em;
		right: 0.25em;
		content: "";
		display: block;
		height: 15px;
		width: 2px;
		background: $white;
		transform: rotate(45deg);
		transform-origin: center;
	}
	&:after {
		transform: rotate(-45deg);
	}
}
