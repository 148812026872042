@import "../colors";

.regional-bureaus-panel {
  .doughnut-chart {
    margin-top: -30px;
  }

  &-charts__container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 2px;
    background: $light-grey;
  }

  .legend {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    max-height: 80px;

    .mark-wrap {
      width: 165px;
    }

    .value {
      width: 60px;
    }
  }

  &-chart {
    display: flex;
    flex-direction: column;
    background: #fff;
    padding: 0 15px;

    .chart-v2 {
      align-self: center;
    }
  }

  &-chart:first-child {
    padding-left: 0;
  }

  &-chart:last-child {
    padding-right: 0;
  }
}

@media (max-width: 1120px) and (min-width: 941px) {
  .regional-bureaus-panel {
    .legend {
      max-height: initial;

      .mark-wrap {
        width: 250px
      }
    }
  }

  .regional-bureaus-panel-chart{
    .title{
      min-height: 55px;
    }
  }
}

@media (max-width: 940px) {
  .regional-bureaus-panel {

    &-charts__container {
      grid-template-columns: 1fr;

      .regional-bureaus-panel-chart {
        padding-top: 20px;
        padding-left: 0;
        padding-right: 0;
      }

      .regional-bureaus-panel-chart:first-child {
        padding-bottom: 20px;
        padding-top: 0;
      }
    }
  }
}

@media (max-width: 515px) {
  .regional-bureaus-panel {
    .legend {
      max-height: initial;

      .mark-wrap {
        width: 250px
      }
    }
  }
}
