// -----------------------------------------------------------------------------
// Module: Header
// -----------------------------------------------------------------------------

.toolbar + .wfp-header-int {
  position: relative;
}

.visible-sm {
  display: block;
  @include media-query($md-screen) {
    display: none;
  }
}

.visible-sm-min {
  display: none;
  @include media-query($sm-screen) {
    display: block;
  }
}
.hidden-sm-min {
  display: block;
  @include media-query($sm-screen) {
    display: none;
  }
}

.visible-md-min {
  display: none;
  @include media-query($md-screen) {
    display: block;
  }
}
.hidden-md-min {
  display: block;
  @include media-query($md-screen) {
    display: none;
  }
}

.visible-lg-min {
  display: none;
  @include media-query($lg-screen) {
    display: block;
  }
}
.hidden-lg-min {
  display: block;
  @include media-query($lg-screen) {
    display: none;
  }
}
/*
.wfp-menu {
  .menu--heading {
    text-transform: none;
    padding: 0.5em 1em;
  }
  .menu--link {
    line-height: 1.4em;
  }
}
.wfp-header-spacer--narrow {
  padding-top: 0;
}

.wfp-header-int {
  min-height: 3.8em;
  position: relative;
  z-index: 20;


  .header--primary,
  .header--secondary {
    @include media-query($md-screen) {
      width: 100%;
      .block {
        display: inline-block;
      }
    }
    .menu--item {
      text-transform: none;
    }
    .content {
      @extend .wfp-wrapper-tight;
      @include media-query($md-screen) {
        background-color: transparent;
        color: #fff;
        text-align: right;
        border-right: 0;
      }
    }
    .header--nav {
      display: block;
      @include media-query($md-screen) {
        display: inline-block;
      }
    }
    .search-toggle {
      .trigger {
        padding: .5em;
        background-color: $blue-darker;
        text-align: center !important;
        color: lighten($blue-dark, 30%);
        border-bottom: 0;
        display: block;
        font-size: .875em;
        @include media-query($md-screen) {
          display: none;
        }
      }
      .target {
        z-index: 2;
        display: none;
        position: absolute;
        width: 100%;
        left: 0;
        top: 2em;
        @include media-query($md-screen) {
          display: block;
          position: relative;
          width: auto;
          top: 0;
        }
      }
      &.visible {
        .trigger {
          background-color: darken($blue-darker, 20%);
        }
        .target {
          display: block;
        }
      }
    }
  }

  .header--secondary {
    background: $blue-darker;
    .content {
      @include media-query($md-screen) {
        padding: 0 1em;
      }
      @include media-query($lg-screen) {
        padding: 0;
      }
      .header--menu {
        margin: .15em .25em;
      }
      .header--menu {
        .menu--link {
          font-size: $small-font-size;
          padding: 0 .25em;
          //color: $blue-lighter;
        }
      }
    }
  }

  .header--primary {
    height: 100%;
    .content {
      @include media-query($md-screen) {
        padding: .75em 1em;
      }
      @include media-query($md-screen) {
        .block {
          float: right;
        }
      }
      @include media-query($lg-screen) {
        padding: .75em 0;
      }
        @include media-query($md-screen) {
          display: block;
        }
        @include media-query($md-screen) {
          font-size: 1em;
        }
        @include media-query($lg-screen) {
          font-size: 1.125em;
        }
        line-height: 2em;
        padding: 0;
        margin: 0;
        text-align: left;
        float: left;
      }
      .menu--link {
        font-size: .875em;
        line-height: 2em;
        font-weight: bold;
        @include media-query($md-screen) {
          padding: 0;
        }
        @include media-query($lg-screen) {
          font-size: 1em;
          line-height: 1em;
          padding: .2em .5em;
        }
      }
      .header--nav {
        .menu--link {
          &.is-active {
            border-bottom: 1px solid $white;
          }
        }
      }
    }
  }
} */

.toolbar + .wfp-header-int {
  &.fixed {
    position: static;
  }
}

/*.wfp-btn {
  &.wfp-btn--icon-only {
    &.xsmall {
      border: 0;
      padding: .5em;
    }
    i {
      margin: 0;
    }
  }
} */

// -----------------------------------------------------------------------------
// Module: Base
// -----------------------------------------------------------------------------

.node--image {
  margin: 0;
  figcaption {
    padding: .5em 1em;
  }
}

.section--header {
  .section--header-title {
    font-size: 1.25em;
    margin: 0;
    padding: 0 0 .5rem 1rem;
    border-bottom: 1px solid $blue-light;
  }
}

// A fix for breadcrumbs overflowing if too long
// This should be back-ported to WFP UI
.wfp-breadcrumbs {
  .breadcrumbs--item {
    display: block;
    float: left;
    margin: -1px 0 0 -1px;
    border-left: 1px solid darken($grey-lighter, 10%);
    background-color: $white;

    &:only-child {
      border-radius: 2px;
    }
  }

  .breadcrumbs--link {
    white-space: nowrap;
  }
}

.breadcrumbs--wrapper {
  margin-bottom: 0;
  padding-top: 1em;
  padding-bottom: 1em;
  .wfp-seg-control {
    margin: 0;
  }
}

.wfp-breadcrumbs {
  margin: 0;
  .breadcrumbs--link.home {
    padding-left: .35em;
    padding-right: .35em;
    span {
      display: none;
      &.icon-home-dark {
        display: inline-block;
      }
    }
  }
}

.seg-control--link {
  &.is-active {
    color: $white;
    background: $blue-darker;
  }
}

.wfp-footer--mini,
.wfp-footer--compact,
.wfp-footer--std {
  margin-top: 0;
}

.print-link {
  display: inline-block;
  margin: 1em 0;
  font-size: .875em;
}
.inline-image {
  display: inline;
  margin-right: .25em;
}

*:focus::-webkit-input-placeholder { opacity:0; }
*:focus:-moz-placeholder { opacity:0; } /* FF 4-18 */
*:focus::-moz-placeholder { opacity:0; } /* FF 19+ */
*:focus:-ms-input-placeholder { opacity:0; } /* IE 10+ */

.small {
  font-size: .875em;
}

a.wfp-btn--primary:visited {
  color: $white;
}
.wfp-btn,
.wfp-btn--primary {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.more-link {
  text-align: center;
  margin-top: 1.5em;
  font-size: .875em;
}

.bordered-block {
  border: 1px solid $blue-dark;
  padding: 2em;
  margin-top: 1.5em;
  margin-bottom: 1.5em;
  .field-label-above {
    font-size: 1.25em;
    margin-bottom: .5em;
    font-weight: bold;
    line-height: 1em;
  }
}

details {
  .details-wrapper {
    display: none;
    text-align: left;
  }
}
details[open],
details[open=open] {
  .details-wrapper {
    display: block;
  }
}

.wfp-form {
  input, textarea {
    box-sizing: border-box;
    width: 100%;
  }
  input.button {
    width: auto;
  }
}

.external-link {
  //background: url(../images/icon-external-link.svg) center right no-repeat;
  display: inline;
  background-size: 1em;
  padding-right: 1.25em !important;
}

#popup-dialog-window {
  padding: 20px;
  border-color: $blue-dark;
  border-radius: 3px;
  h2 {
    font-size: 1em;
    color: $grey-darker;
  }
}
#popup-header {
  margin-bottom: 10px;
}
#popup-dialog-content {
  font-size: .9em;
  color: $grey-darkest;
}

.dsa-filter-form {
  .form-item {
    display: inline-block;
  }
}
