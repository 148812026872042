@import "../colors";

.projected-requirements-panel {
  .indicators-focus-area {
    margin-bottom: 20px;
  }

  .bar-indicator-row__item-label {
    width: 210px;
  }

  .bar-indicator-row__desc {
    width: 100%;
  }
}


@media screen and (max-width: 776px) {
  .projected-requirements-panel {

    .bar-indicator-row__item-label {
      width: 190px;
    }
  }
}