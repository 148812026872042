@import '../../colors';

.bar-indicator,
.compare-bar-indicator {
	flex-grow: 1;
	display: flex;
	flex-direction: row;
	align-items: center;
	position: relative;

	&__scale-container {
		justify-self: center;
		align-self: center;
		display: flex;
		overflow: hidden;
		border-radius: 10px;
		margin-right: 2%;
	}

	&__prev {
		height: 24px;
		position: absolute;
		top: -6px;
		border-left-width: 2px;
		border-left-color: $black;
		z-index: 1;
	}

	&__third {
		top: 3px;
		height: 6px;
		background-color: $bar-light-blue-third;
		border-radius: 9px;
		position: absolute;
	}

	&__scale-wrap {
		position: relative;
		display: inline-flex;
		width: 100%;
		height: 12px;

		.scale {
			height: 12px;
			background-color: $bar-blue;
			justify-self: center;
			align-self: center;
		}

		.label {
			font-size: 12px;
			color: $navy;
			margin-left: 5px;
			margin-top: -4px;
			white-space: nowrap;
		}

		.scale.single {
			width: 100%;
		}

		.scale:nth-child(2) {
			background-color: $bar-light-blue;
		}

		.scale:nth-child(3) {
			background-color: $bar-light-blue-third;
		}

		.scale:nth-child(4) {
			background-color: #c8eef7;
		}

		.scale:nth-child(5) {
			background-color: #d6dded;
		}
	}

	.value-suffix {
		font-size: 14px;
		margin-right: 5px;
		font-weight: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
	}

	&__value {
		padding-right: 10px;
		font-size: 14px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		white-space: nowrap;
		justify-self: center;
		align-self: center;
		text-align: right;
		min-width: 65px;
		font-family: 'Open Sans', sans-serif;

		.value-symbol {
			font-weight: normal;
			font-stretch: normal;
			line-height: normal;
			letter-spacing: normal;
		}
	}

	&__percents {
		min-width: 55px;
	}
}

.bar-indicator.dark,
.compare-bar-indicator.dark {
	color: $navy;
}

.bar-indicator.white,
.compare-bar-indicator.white {
	color: white;
}

.compare-bar-indicator {
	&__scales {
		position: relative;
		width: 100%;
		display: flex !important;
		align-items: center;

		.bar {
			position: absolute;
			height: 10px;
			border: solid 1px $grey-blue;
			background-color: $grey-blue;
			border-radius: 9px;
		}

		.line {
			position: absolute;
			height: 24px;
			border-right: 2px solid $navy;
		}

		.tooltip {
			z-index: 1;
			height: 10px;
		}
	}
}

.bar-tooltip {
	&__title {
		font-weight: 700;
		padding-bottom: 10px;
		min-width: 150px;
	}

	&__item {
		display: flex;
		flex-direction: row;
		margin-bottom: 5px;

		.year {
			color: $blue-grey;
			padding-right: 10px;
		}

		.label {
			padding-right: 10px;
			font-weight: 600;
		}

		.mark {
			border-radius: 22px;
			margin-right: 7px;
			width: 16px;
			height: 16px;
		}
	}

	.extra-data-wrap {
		display: flex;
		flex-direction: column;
		margin-left: 25px;

		&__item {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			font-size: 14px;

			.value {
				margin-left: 10px;
			}
		}
	}

	&__item.horizontal {
		.value {
			margin-left: auto;
		}
	}

	div:nth-child(2) {
		.mark {
			background-color: $bar-blue;
		}
	}

	div:nth-child(3) {
		.mark {
			background-color: $bar-light-blue;
		}
	}

	div:nth-child(4) {
		.mark {
			background-color: $bar-light-blue-third;
		}
	}

	div:nth-child(5) {
		.mark {
			background-color: #c8eef7;
		}
	}

	div:nth-child(6) {
		.mark {
			background-color: #d6dded;
		}
	}
}

.bar-indicator-row {
	&__container {
		display: flex;
		flex-basis: 45%;
		justify-content: space-between;
		align-items: center;
		position: relative;
		height: 32px;

		.tooltip {
			flex: 1;
			cursor: pointer;
			position: initial;
			display: flex;
			align-items: center;
		}
	}

	&__main-content {
		display: flex;
		overflow: hidden;
	}

	&__desc {
		width: 47%;
		min-width: 142px;
		position: relative;
		margin-right: 3%;
		overflow-x: hidden;
		display: flex;
		align-items: center;
	}

	&__item {
		margin-right: 5px;
		font-size: 14px;
		font-family: 'Open Sans', sans-serif;
		white-space: nowrap;
	}

	&__item.order {
		overflow-x: visible;
		margin-bottom: 0;
		width: 20px;
	}

	&__item-label {
		width: 160px;
	}

	&__item-label-tooltip {
		position: absolute;
		top: -25px;
		padding: 4px 5px;
		font-size: 14px;
		font-weight: 300;
		color: $navy;
		background: #eeeeee;
		border-radius: 3px;
		font-family: 'Open Sans', sans-serif;
		white-space: nowrap;
	}
}

.indicators {
	&__container {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
		grid-column-gap: 20px;
	}

	&-row {
		&__title {
			font-size: 16px;
			line-height: 26px;
			font-family: 'Open Sans', sans-serif;
			font-weight: 600;

			&__container {
				margin-bottom: 12px;
			}
		}

		&__subtitle {
			font-size: 14px;
			line-height: 23px;
			font-family: 'Open Sans', sans-serif;
			color: $slate-grey;
			font-weight: 600;
		}
	}
}

@media (max-width: 400px) {
	.bar-indicator__percents {
		display: none;
	}
}
