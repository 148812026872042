
.local-economies-panel {
  display: flex;
  flex-direction: column;

  .chart, .chart__container {
    width: 100%;
    max-width: none;
  }

  .legend {
    margin-top: 20px;
    width: 350px;
    align-self: center;

    .tooltip {
      position: relative;
      color: var(--interactive-01);
      text-decoration: underline;
    }

    .value-container {
      margin-left: 10px;

      .percents {
        margin-left: 8px;
      }
    }
  }

  .disclaimer {
    font-size: 12px;
    line-height: 23px;
    color: #5a6872;
    font-weight: 600;
    text-align: left;
    font-style: normal;
  }

  .chart-v2 {
    justify-content: center;
    margin-top: -30px;
  }

  .doughnut-chart__container {
    display: flex;
    flex-direction: column;
  }
}

