@import "../colors";

.resources-panel {

  &__inner {
    background: #fff;
    padding-right: 25px;
  }

  .chart-indicator-panel {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    grid-gap: 2px;
    background: $light-grey;

    &__bottom {
      padding-left: 25px;
      background: #fff;
    }

    .indicators__container {
      grid-template-columns: initial;

      .bar-indicator-row__desc {
        width: initial;
      }

      .indicators-annual {
        margin-bottom: 20px;
      }
    }

    .legend-wrap {
      width: 310px;
      margin-top: 30px;
      align-self: center;
    }
  }

  .chart.center {
    align-items: flex-start;
    width: 100%;
    max-width: initial;
  }

  .chart {
    &__container {
      flex-direction: column;
      width: 100%;
      display: flex;
    }

    .chart-wrap {
      display: flex;
      flex-direction: column;

      .chart-v2 {
        align-self: center;
        flex-wrap: wrap;
        margin-top: -25px;
      }
    }
  }

  .bar-indicator-row {
    &__desc {
      min-width: 175px;
    }

    &__item-label {
      width: 170px;
    }
  }

  .chart.center > div {
    width: 100%;
  }

  .bar-indicator__value.breakdown {
    color: var(--interactive-01);

    .value, .value-symbol {
      text-decoration: underline;
    }
  }
}

@media screen and (max-width: 1050px) {
  .resources-panel .chart-indicator-panel {
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  }
}

@media screen and (max-width: 776px) {
  .resources-panel {
    &__inner {
      padding-right: 0;
      padding-bottom: 15px;
    }

    .chart-indicator-panel__bottom {
      padding-top: 15px;
      padding-left: 0;
    }
  }
  .resources-panel .chart-indicator-panel {
    grid-template-columns: 1fr;

    .legend-wrap {
      width: 100%;
    }
  }
}
