@import '../../colors';

.percent-progress-indicator {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-self: center;
  margin-bottom: 10px;

  &__label {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    font-size: 12px;
    font-weight: bold;
    margin-bottom: 4px;
    position: relative;
  }

  &__label.dark {
    color: $grey-blue;
  }

  &__label.light {
    color: white;
  }

  &__label.hide {
    display: none;
  }

  .progress-symbol {
    font-size: 14px;
    color: $navy;
    white-space: nowrap;
    min-width: 70px;
  }

  .progress-symbol.hide {
    display: none;
  }
}

.indicator-arrow {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  min-width: 80px;

  &__icon {
    cursor: pointer;
  }

  &__percent {
    padding-left: 2px;
    font-size: 14px;
    min-width: 30px;

  }
}
