/**
 * Info
 *
 * The Info and Share icons in each component
 * found on all pages.
 */

.wfp-meta {
  position: absolute;
  bottom: 0;
  border-top: 1px solid $grey-lighter;
  background: rgba($white, 0.3);
  left: 1rem;
  right: 1rem;
  z-index: 0;
  .wfp-meta--hidden & {
    display: none;
  }
  span:first-child {
    .wfp-meta__element {
      margin-left: 0;
    }
  }
  .panel__sub & {
    left: 0;
    right: 0;
  }
  .map & {
    position: relative;
    border-top: 0;
    padding: 0;
  }
  padding: .6rem .2rem;
  text-align: right;
  font-size: 0.85em;
  .share-box & {
    display: none;
  }
  .panel--meta--small & {
    left: inherit;
    border-top: 0;
  }
  .panel--dark & {
    background: none;
    .share-info {
      &:after {
        color: transparent;
        -webkit-text-stroke: 1.3px $white;
      }
    }
  }
}

.wfp-meta__element {
  white-space: nowrap;
  margin-left: 1.5em;
  display: inline-block;
  border-width: 0;
  margin-bottom: 1px;
  user-select: none;
  &:hover {
    border-width: 1px;
    margin-bottom: 0;
  }
  //font-weight: 400;
}

.meta--text {
  position: relative;
  top: -2px;
}

.tooltip--info {
  &:after {
    font-family: FontAwesome;
    content: "\f107";
    padding-left: 0.3em;
  }
}

.share-info {
  display: none;
  &:after {
    font-family: FontAwesome;
    content: "\f064";
    color: $white;
    font-size: 0.9em;
    display: inline-block;
    transform: translateY(-0.5px);
    -webkit-text-stroke: 1.3px darken($blue-darker, 5%);
    padding-left: 0.4em;
  }
}