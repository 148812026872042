@import '../../colors';

.tabs {
	position: sticky;
	top: 0;
	z-index: 3;

	&__container {
		display: flex;
		align-items: flex-end;
	}

	.wfp--tabs__nav-item {
		font-size: 1rem;

		a:hover {
			color: $secondary-blue;
		}

		&--selected {
			a:hover {
				color: $nice-blue;
			}
		}
	}

	&__emergency-title {
		display: flex;
		flex-direction: row;
		flex-flow: row wrap;
		align-items: center;
		min-height: 70px;
		font-weight: 600;

		.title-wrap {
			display: flex;
			flex-direction: row;
			align-items: center;
			flex-wrap: wrap;

			.title {
				margin-right: 15px;
				font-size: 100%;
				display: flex;
			}

			.links {
				display: flex;
				flex-direction: row;
				align-self: center;
				font-size: 14px;
				margin-bottom: -10px;

				.acr-link {
					margin-left: 20px;
				}

				.acr-link,
				.csp-link {
					text-decoration: none;

					&:hover {
						text-decoration: underline;
					}
				}
			}
		}
	}

	.wfp--secondary-navigation__list {
		padding-top: 0;
	}
}

.breadcrumbs {
	padding-top: 1.5rem;
	background-color: white;
	z-index: 3;
}
.header-container {
	z-index: 4;
}
