@import '../../colors';

.chart-v2 {
  display: flex;
  flex-direction: row;
  align-items: center;

  .doughnut-chart, .progress-doughnut-chart {
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.57;
    letter-spacing: normal;
    text-align: center;

    .top-text {
      fill: $navy;
    }

    .center-text {
      fill: $navy;
      font-weight: bold;
      font-size: 16px;
    }

    .bottom-text {
      font-size: 10px;
      font-weight: 600;
      width: 70px;
      line-height: 1.83;
      fill: $blue-grey;
      text-transform: uppercase;
    }
  }
}

@media screen and (max-width: 375px) {
  .chart-v2 {
    flex-direction: column;
  }
}

