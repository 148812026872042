/**
 * Panels
 *
 * All panels
 * .panel => Main Panel with blue Border
 * .panel__sub => Sub Panel with lightBlue Border inside .panel
 */

/* Flex Grid extension for .wfp-grid */

.wfp-grid--flex {
  display: flex;
  flex-flow: row wrap;
  flex-wrap: wrap;
  @include media-query($md-screen) {
    margin: 0 -0.5em;
  }
  @include print {
    margin: 0 -0.2em;
  }
  .wfp-u-1 {
    align-items: stretch;
  }
}

.section {
  @include media-max($md-screen) {
    overflow: hidden;
  }
  width: 100%; // Legacy for old grid
  &:last-child {
    padding-bottom: 0.75rem;
  }
}

/* new Panel */

.panel--new {
  position: relative;
  .wfp--module__inner {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .wfp-meta {
    position: relative;
    width: 100%;
    border: none;
    left: inherit;
    right: inherit;
    padding: 0;
  }
}

/* Panel */
.panel {
  margin: 0; // TODO: Remove Overrides for Bootstrap
  border: none;
  padding: 0.4em;
  background: none;
  box-shadow: none;
  @include media-max($md-screen) {
    padding: 0.5rem 1rem;
  }
  @include media-query($md-screen) {
    padding: 0.5rem;
  }
  .generic_page_content & {
    border-radius: 0px !important;
    margin-bottom: 1em;
    text-align: left;
    background: $white;
    margin: 0;
    padding: 1rem;
    height: 100%;
    padding-bottom: 3rem;
    position: relative;
    border: 1px solid lighten($blue-lighter, 1);

    @include media-query($md-screen) {
      padding: 1.5rem 1.5rem 3rem 1.5rem;
      //box-shadow: 1px 3px 3px rgba($blue, 0.03) !important;
      box-shadow: 1px 3px 3px rgba($blue, 0.1) !important;
    }
    margin-bottom: 1rem;
  }

  .info-react & {
    > div, .panel__inner {
      position: relative;
      border-radius: 0 !important;
      margin-bottom: 1em;
      text-align: left;
      background: $white;
      margin: 0;
      padding: 1rem;
      height: 100%; //border: 1px solid lighten($blue-lighter, 1);
      @extend .main-shadow;
      @include print {
        border: 1px solid rgba($blue, 0.3) !important;
        padding: 1rem;
      }
      @include media-query($md-screen) {
        padding: 1rem; //box-shadow: 1px 3px 3px rgba($blue, 0.03) !important;
      }
      @include media-query($xl-screen) {
        padding: 1.5rem; //box-shadow: 1px 3px 3px rgba($blue, 0.03) !important;
      }
    }
  }
  h4 {
    font-size: 1rem;
    font-weight: normal;
    color: $grey;
    margin-bottom: 1rem;
    font-style: italic;
  }
  .panel-heading {
    height: 0;
    margin: 0;
    margin-bottom: 1rem;
    padding: 0;
    user-select: none;
    cursor: pointer;
    min-height: 30px;
    white-space: nowrap;
    float: right;
  }
  .panel-body {
    padding: 0;
  }
}

.panel.panel--meta {
  > div, .panel__inner {
    padding-bottom: 3rem;
  }
  .chart--implementation /*, .chart--trend */
  {
    margin-bottom: 3rem;
  }
}

.panel.panel--meta--small {
  > div, .panel__inner {
    padding-bottom: 2rem;
  }
  .chart--implementation /*, .chart--trend */
  {
    margin-bottom: 2rem;
  }
}

.panel--with-sectiontitle.panel {
  > div {
    height: calc(100% - 2.5em);
  }
}

.panel--no-padding {
  > div, .panel__inner {
    padding: 0 !important;
    .panel__sub--padding {
      padding: 1em;
    }
  }
}

.panel.panel--trend {
  > div, .panel__inner {
    padding-bottom: 9rem;
  }
}

.panel__sub.panel--meta {
  > div, .panel__inner {
    padding-bottom: 3rem;
  }
}

.panel--verticalcenter {
  > div, .panel__inner {
    width: 100%;
    display: flex !important;
    flex-basis: 100%;
    align-items: center;
  }
}

.panel__sub--verticalcenter {
  //height: 100%;
  > div, .panel__inner {
    height: 100%;
    display: flex !important;
    align-items: center;
  }
}

.panel__sub--verticalcenter--withheading {
  margin-top: -1em;
}

.panel__sub--verticalcenter-heading {
  @extend .panel__sub--verticalcenter;
  margin-top: -1rem;
  margin-bottom: 1rem;
}

/* Dark Panels */

.info-react .panel.panel--dark {
  > div, .panel__inner {
    @extend .inverted;
  }
}

.panel__textonly {
  text-align: center;
  font-weight: normal;
  a {
    margin-top: 1em;
    font-size: 0.8em !important;
  }
  /*&:after {
      font-family: FontAwesome;
      content: "\f002";
      display: inline-block;
      width: 100%;
  }*/
}

/* Subpanels */

.panel__sub {
  //padding: 0;
  > div, .panel__inner {
    position: relative;
  }
  margin-bottom: 1em;
  margin-bottom: 0;
  border-bottom: 3px solid $blue-lightest;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  &:last-child {
    border-bottom: 0px;
    padding-bottom: 0;
    margin-bottom: 0;
  }
  @include media-query($md-screen) {
    //padding: 0;
    border-bottom: 0;
    margin-bottom: 0;
    &:after {
      height: 3px;
      content: "";
      background: $blue-lightest;
      position: absolute;
      right: 0.5em;
      left: 0em;
      bottom: -1em;
    }
    &.wfp-u-lg-1-2, &.col-md-6,
    &.wfp-u-md-1-2,
    &.wfp-u-1-2 {
      border-right: 3px solid $blue-lightest; //padding-top: 2em;
      &:nth-child(2n) {
        padding-left: 1.5em;
        border-right: none;
        &:after {
          left: 0.5em;
          right: 0em;
        }
      }
      &:nth-child(2n+1) {
        padding-right: 1.5em;
      }
      &:nth-last-of-type(-n+2) {
        &:after {
          display: none;
        }
      }
    }
    &.wfp-u-lg-1-3,
    &.wfp-u-md-1-3,
    &.wfp-u-1-3 {
      border-right: 3px solid $blue-lightest;
      &:nth-child(3n+2),
      &:nth-child(3n) {
        padding-left: 1.5em;
        /*&:after {
            left: 0.1em;
            right: 0em;
            display: block;
        } */
      }
      &:nth-child(3n) {
        border-right: 0;
      }
      &:nth-child(3n+1),
      &:nth-child(3n+2) {
        padding-right: 1.5em;
      }
      &:nth-last-of-type(-n+3) {
        &:after {
          display: none;
        }
      }
    }
  }
}

.panel__sub__seperated {

  @include media-max($md-screen) {
    margin-bottom: 1em;
    margin-bottom: 0;
    border-bottom: 3px solid $blue-lightest;
    &:last-child {
      border-bottom: 0;
    }
  }

  @include media-query($md-screen) {
    margin-bottom: 1em;
    margin-bottom: 0;
    padding-bottom: 1rem;
    border-right: 3px solid $blue-lightest;
    &:nth-child(2) {
      padding-left: 1rem;
    }
    &:last-child {
      border-right: 0;
    }
  }

}

.panel__seperated {
  padding-bottom: 0.6em;
  margin-bottom: 0.8em;
  border-bottom: 3px solid $blue-lightest;
  &:last-child {
    &:not(.ignore-last) {
      padding-bottom: 0;
      margin-bottom: 0;
      border-bottom: none;
    }
  }
}

.panel__description {
  //font-style: italic;
  //font-size: 0.95em;
  //background: lighten($grey-lightest,1);
  //border-left: 3px solid $blue;
  padding: 1rem 1.5rem;
  margin: 0 -1.5rem;
  margin-top: 0;
  //margin-bottom: 1.5rem;
}

.sectiontext--iconwrapper {
  width: 100%;
  display: flex;
  align-items: center;
}

.sectiontext {
  &p {
    display: block;
  }
  .icon {
    margin-left: 2em;
    margin-right: 3em;
    @include media-max($md-screen) {
      display: none;
    }
  }
  a, b {
    display: inline-block;
    padding: 0 0.1em;
    //font-size: 1.3em;
    text-shadow: 1px 1px 4px rgba($black, 0.1);
    font-style: italic;
    transform-origin: bottom; //transform: scale(1.3);
  }
}

.sectiontext--small {
  font-size: 0.9em;
}

.info-react .panel.panel--dark > div {
  &.sectiontext, .sectiontext {
    font-size: 1em;
    //color: rgba($white, 0.9);
    a, b {
      color: $white;
    }
  }
}

.wfp--module {
  display: flex;
  height: 100%;
  flex-direction: column;

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .wfp--form-item {
      margin-bottom: 0;
    }
  }
  &__content {
    flex: 1;
    background-color: $white;
  }
  &__footer {
    height: 35px;
  }
}

.wfp--module--dark {
  .wfp--module__content {
    background-color: #085387;
  }
}

.wfp--select {
  width: 100%;
}
