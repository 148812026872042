.switch {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  &__label {
    font-weight: 700;
  }

  &__control {
    margin-right: 10px;

    &__container {
      display: flex;
      align-items: center;
    }
  }
}
