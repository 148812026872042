@import "../../colors";

.info-indicator {
  cursor: pointer;
  display: flex;
  align-items: center;

  .hover-info {
    height: 18px;
    max-width: 250px;

    .info-link {
      font-size: 12px;
      color: $nice-blue;
      text-decoration: underline;
    }
  }
}

.tooltip-info {
  line-height: normal;
  z-index: 100;
}

div[x-placement='top']{
  .arrow-regular {
    border-top: 7px solid $navy;
  }
}

div[x-placement='bottom']{
  .arrow-regular {
    border-bottom: 7px solid $navy;
  }
}

.tippy-tooltip.dark-theme {
  background-color: #031C2D;
  border: 1px solid $big-stone;

  .breakdown-label, .bar-tooltip__title {
    color: $saffron;
  }
}
