@import "../../../colors";

.strategic-outcome-activity {
  margin: 0 40px 40px 40px;
  border: 2px solid $geyser;
  border-radius: 4px;

  &__title {
    color: white;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.94;
    background-color: $slate-grey;
    padding: 15px 20px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  &__content {

    .percent-indicators {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));

      .big-percent-indicator {
        padding: 0 25px;
        margin: 25px 0;
        border-right: 1px solid $geyser;
      }

      .big-percent-indicator:last-child {
        border-right: none;
      }

      .big-percent-indicator__sub-labels {
        display: flex;
        flex-direction: column;

        .left, .right {
          display: flex;
          flex-direction: row;

          .label {
            min-width: 80px;
          }
        }
      }
    }

    .outputs-table {
      padding: 25px 25px 0 25px;

      .rt-thead.-header {
        border-top: 1px solid $geyser;
        border-bottom: 1px solid $geyser;

        .rt-th {
          min-height: 96px;
          padding: 15px 20px;
        }
      }

      .rt-th, .rt-td {
        border-right: 1px solid $geyser;
      }

      .resources {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .one-line-indicator {
          font-size: 16px;
          line-height: 1.38;
          width: 100%;
          padding-bottom: 20px;

          .label {
            font-weight: normal;
            text-transform: none;
            width: 281px;
            padding-right: 20px;
          }

          .two-rows-cell {
            display: flex;
            flex-direction: row;

            &__value {
              padding-right: 10px;
            }
          }
        }

        .one-line-indicator:last-child {
          padding-bottom: 0;
        }
      }

      .rt-tr-group:last-child {
        border-bottom: none;
      }
    }
  }

  &__info {
    min-width: 150px;

    .title {
      font-weight: 600;
      font-size: 16px;
    }

    .one-line-indicator {
      font-size: 14px;
      margin-top: 5px;

      .label {
        font-weight: normal;
      }

    }
  }
}
