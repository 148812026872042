@import "../../colors";

.horizontal-bar-chart {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  padding: 8px 0;

  &__group {
    padding-bottom: 20px;

    .label {
      color: $navy;
      font-weight: 600;
      font-size: 14px;
      line-height: 23px;
    }

    .indicator {
      display: flex;
      align-items: center;

      .bar {
        height: 14px;
        border-bottom-right-radius: 7px;
        border-top-right-radius: 7px;
      }

      .value {
        font-size: 12px;
        color: $navy;
        margin-left: 5px;
        white-space: nowrap;
      }
    }

    .bars-wrap {
      position: relative;

    }
  }
}

.legend {
  &-horizontal {
    display: flex;
    flex-wrap: wrap;

    .mark-wrap {
      margin-right: 10px;
    }
  }

  .mark-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 5px;
  }

  .mark {
    border-radius: 22px;
    margin-right: 7px;
    width: 16px;
    height: 16px;
  }

  .label {
    font-style: normal;
    font-weight: normal;
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    color: $slate-grey;
  }
}

.tooltip {
  z-index: 1;
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
