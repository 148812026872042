@import "../colors";

$border-color: rgba(0, 0, 0, 0.1);

.strategic-outcome-panel {
  .wfp--module__content {
    padding: 0;
  }

  &__table {
    .rt-thead .rt-th, .rt-tfoot .rt-td, .rt-tbody .rt-td {
      min-height: 95px;
      padding: 25px 10px;
      border-right: none;
      line-height: normal;
    }

    .rt-thead.-header {
      .title-header-column {
        margin-top: -95px;
        height: 235px;
      }

      .rt-th {
        padding-right: 20px;
        min-height: 140px;
      }
    }

    .rt-tfoot{
      border-top: solid 1px $border-color;
    }

    .rt-thead.-headerGroups {
      border-bottom: none;

      .rt-th {
        justify-content: center;
        padding: 0;
      }

      .border-bottom {
        border-bottom: solid 1px $border-color;
      }

      .two-rows-title {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 95px;
        justify-content: center;
        border-right: solid 1px $border-color;
        border-left: solid 1px $border-color;

        div {
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        :first-child {
          border-bottom: solid 1px $border-color;
        }
      }
    }

    .rt-th.left-border {
      border-left: solid 1px $border-color;
    }

    .rt-th.right-border {
      border-right: solid 1px $border-color;
    }

    .rt-thead, .rt-thead.-headerGroups {
      background-color: $athens-gray;
    }

    .two-rows-cell {
      &__percent {
        color: $slate-grey
      }
    }

    .title-column {
      &__label {
        display: flex;
        flex-direction: column;

        .label {
          display: flex;
          flex-direction: row;
          color: $navy;
          font-size: 16px;
          font-weight: 600;
          height: 25px;
          align-items: center;

          .info-indicator {
            margin-left: 10px;
          }

          .square-indicator {
            height: 16px;
            width: 16px;
            margin-right: 10px;
          }
        }

        .sub_title {
          margin-left: 26px;
          font-size: 14px;
          display: flex;
          flex-direction: column;
        }
      }
    }

    .rt-noData {
      height: 100%;
      justify-content: center;
      align-items: center;
      display: flex;
    }
  }
}
