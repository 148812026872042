@import "colors";

.mobile {

  &__header {
    box-sizing: border-box;
    background: #fff;
    background: linear-gradient(135deg, rgba(255,255,255,1) 0%, #f6f6f6 100%);
    border-bottom: 2px solid $light-grey;

    &-inner {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &-main {
      flex-basis: 60%;

      &__title {
        margin-bottom: 40px;
        font-weight: 600;
        font-size: 45px;
      }

      &__desc {
        margin-bottom: 50px;
      }

      &__links {
        display: flex;
        align-items: center;
      }

      &__link {
        cursor: pointer;
        text-decoration: underline;
        color: $water-blue;
        margin-right: 30px;
        font-size: 18px;
      }

      &__link-image {
        cursor: pointer;
      }
    }

    &-image {
      margin-top: 40px;
      height: 600px;
      //width: 400px;
      object-fit: cover;
    }
  }

  &__main {
    padding: 80px 10px;
    box-sizing: border-box;

    &-section {
      margin-bottom: 100px;
    }

    &-section:last-child {
      margin-bottom: 0;
    }

    &-label {
      font-family: 'Open Sans', sans-serif;
      font-size: 34px;
      font-weight: 400;
      margin-bottom: 50px;
    }

    &-steps {
      margin-bottom: 60px;
    }
  }

  &__step {
    font-family: 'Open Sans', sans-serif;
    margin-bottom: 60px;

    &-label {
      font-size: 20px;
      margin-bottom: 20px;
    }

    &-row {
      display: flex;
      justify-content: space-between;
    }

    &__image-container {
      display: flex;
      justify-content: center;
      align-items: center;

      .image {
        width: 350px;
        max-height: 350px;
        background: rgba(196, 196, 196, 0.1);;

        &-fill {
          padding: 20px 40px;
          box-sizing: border-box;
          object-fit: contain;
          border: 1px solid $light-grey-border;
          background: none;
        }
      }
    }

    &-list {
      flex-grow: 1;
      margin-left: 50px;
      padding-left: 30px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      list-style: initial;

      .item {
        margin-bottom: 15px;
        font-family: 'Open Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 26px;

        .link {
          color: $navy
        }
      }
    }
  }

  &__step:last-child {
    margin-bottom: 0;
  }

  &__help {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 60px;
    background: $lighter-blue;
    border-radius: 5px;

    &__title {
      font-size: 40px;
      font-weight: 400;
      text-align: center;
      margin-bottom: 30px;
    }

    &__desc {
      margin-bottom: 40px;
      max-width: 600px;
      line-height: 26px;
      text-align: center;
    }

    &__link {
      background: $water-blue;
      padding: 12px 20px;
      font-size: 14px;
      font-weight: 600;
      color: #fff;
      border-radius: 4px;
      text-decoration: none;
    }
  }
}

@media(max-width: 1000px) {
  .mobile {
    &__step {
      &-row {
        flex-wrap: wrap;
        justify-content: center;
      }
    }


    &__header {
      &-main {

        &__title {
          font-size: 35px;
        }

        &__links {
          flex-direction: column;
          align-items: flex-start;
        }

        &__link {
          margin-left: 0;
          margin-bottom: 20px;
        }
      }
    }
  }
}

@media (max-width: 750px) {
  .mobile__header {
    padding: 20px;

    &-image {
      display: none;
    }

    &-main {
      flex-basis: initial;
    }
  }

  .mobile__step-list {
    margin-left: 0;
    margin-top: 20px;
  }
}

@media (max-width: 370px) {
  .mobile__step__image-container {
    .image {
      width: 100%;
    }
  }
}
