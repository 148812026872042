@import "../../colors";

.xlsx-export {
  display: flex;
  align-items: center;

  &__title {
    font-size: 12px;
    font-weight: 600;
  }

  &__subitem {
    display: flex;
    &__value {
      font-size: 14px;
      color: $nice-blue;
      cursor: pointer;
      font-weight: 700;
      margin-right: 4px;
      padding: 0;
    }

    .wfp--inline-loading {
      width: initial;
    }

    &__value:hover {
      text-decoration: underline;
    }

    &__value:disabled {
      color: #8C9BA5;
      cursor: initial;

      &:hover {
        text-decoration: none;
      }
    }
  }

  &__item {
    display: flex;
    &__value {
      font-size: 14px;
      color: $nice-blue;
      cursor: pointer;
      font-weight: 700;
      margin-left: 4px;
    }
    &__value span:hover {
      text-decoration: underline;
    }

    &__value:disabled {
      color: #8C9BA5;
      cursor: initial;

      span:hover {
        text-decoration: none;
      }
    }
  }
}
