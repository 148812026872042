.rt-td--number {
    display: block !important;
    >span {
        display: block;
        color: $white;
        width: 34px;
        height: 34px;
        line-height: 32px;
        border-radius: 34px;
        text-align: center;
        background: $brand-01;
    }
}