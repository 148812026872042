@import "../../colors";

.header-list-indicators {
  background-color: $navy;
  padding-bottom: 20px;
}

.header-list-indicators.top-border {
  border-top: 1px solid $b-grey;
}

.header-list-indicators.empty {
  padding: 20px;
}
