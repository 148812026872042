@import '../colors';

.partners-panel-content {
	.legend {
		margin-top: 20px;
	}

	.chart {
		flex: 1;
		padding-right: 19px;

		&__title {
			width: 100%;
		}
	}

	.doughnut-chart {
		margin-top: -25px;
	}

	.chart-indicator-panel {
		display: grid;
		grid-template-columns: 1fr 2fr;
		grid-gap: 2px;
		background-color: #e1e7eb;
		flex-wrap: wrap;

		&__bottom {
			margin-top: 0;
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-gap: 2px;
			background-color: #e1e7eb;

			.top-grouped-indicators {
				background: #fff;

				.bar-indicator-row__desc {
					min-width: 180px;
				}

				.bar-indicator {
					&__percents {
						min-width: 50px !important;
					}
				}
			}
		}
	}

	.partners-panel-content__inner {
		flex: 1;
		background: #fff;

		.doughnut-chart__container {
			justify-content: center;
			display: flex;
		}

		.chart-indicator-panel__top {
		}

		.chart {
			flex: 1;
		}
	}

	&__indicators {
		display: flex;
		justify-content: space-around;
	}

	.top-grouped-indicators {
		padding: 0 15px;
	}

	.bar-indicator-row__desc {
		width: 130px;
	}

	.chart-indicator-panel__top {
		justify-content: center;
	}

	.chart-indicator-panel__bottom {
		min-height: 180px;
		flex: 2;

		.title__subtitle {
			margin-top: 5px;
			margin-bottom: 10px;

			.tooltip {
				position: relative;
				width: auto;
				color: var(--interactive-01);
				text-decoration: underline;
			}
		}
	}

	.grouped-indicators {
		flex-grow: 1;
	}
}

@media (min-width: 1024px) and (max-width: 1150px) {
	.partners-panel-content.chart-indicator-panel {
		grid-template-columns: 1.1fr 2fr;

		.chart-indicator-panel__bottom {
			.top-grouped-indicators .bar-indicator-row__desc {
				min-width: 145px;
			}
		}
	}
}

@media (max-width: 1024px) {
	.partners-panel-content {
		.chart {
			padding-right: 0;
		}

		.partners-panel-content__inner {
			padding-bottom: 15px;
		}

		.chart-indicator-panel {
			grid-template-columns: 1fr;
		}

		.chart-indicator-panel__bottom {
			grid-template-columns: 1fr;

			.top-grouped-indicators {
				padding: 10px 0 5px 0;
			}
		}
	}
}

@media (max-width: 400px) {
	.partners-panel-content {
		.legend__label {
			flex-direction: column;
		}

		.chart-indicator-panel__bottom {
			.top-grouped-indicators .bar-indicator-row__desc {
				min-width: 145px;
			}
		}
	}
}
