@import '../colors';

.performance-panel {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));

  .performance-panel-column {
    padding: 0 30px;
    display: flex;
    flex-direction: column;
    border-right: 1px solid $geyser;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;

    &__title {
      font-size: 16px;
      font-weight: 600;
    }

    &__pie-chart {
      align-self: center;
      min-height: 200px;
      margin-bottom: 60px;
    }

    &__bar-chart {
      width: 100%;
      min-height: 180px;
      align-self: center;
      margin-bottom: 10px;

      .title {
        font-size: 14px;
        font-weight: 600;
      }

      .subtitle {
        font-size: 12px;
        font-weight: bold;
        color: $blue-grey;
      }

      .chart__wrapper {
        min-height: 180px;
      }
    }

  }

  .performance-panel-column:first-child {
    padding-left: 0;
  }

  .performance-panel-column:last-child {
    padding-right: 0;
    border-right: 1px solid rgba(255, 255, 255, 0);
  }
}

@media screen and (max-width: 768px) {
  .performance-panel {
    display: flex;
    flex-direction: column;

    .performance-panel-column{
      padding-left: 0;
      padding-right: 0;
      border-right: none;
    }
  }
}
