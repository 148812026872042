@import "../../colors";

.director-indicator {
  display: flex;
  flex-direction: row;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-bottom: 8px;

  &__logo {
    width: 60px;
    height: 60px;
    border-radius: 50%;

    .wfp--user__icon--empty {
      width: 100%;
      height: 100%;
      border: none;
      overflow: auto !important;
    }

    .wfp--user__icon {
      margin-right: 0;
    }
  }

  &__info {
    padding-left: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .title {
      font-weight: bold;
    }
  }
}

.deputy-director-title {
  color: #F7B825;
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 5px;
  font-weight: 700;
}

.deputy-director {
  margin-bottom: 5px;

  .title {
    display: none;
  }

  .director-indicator__logo {
    width: 30px;
    height: 30px;
  }
}

.deputy-director:last-child {
  margin-bottom: 13px;
}
