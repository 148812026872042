.react-tabs__tab-list {
    display: flex;
    margin: 0;
    padding: 0;
    h3 {
        flex-grow: 1;
    }
    li {
        padding: 0 0.5em;
        list-style-type: none;
        margin: 0;
        cursor: pointer;
        line-height: inherit;
		border: 1px solid $white;
        @include print {
            display: none;
        }
		&:focus {outline:0;}
    }
    .wfp-info {
        @include print {
            display: none;
        }
    }
}

@keyframes tab-active {
    0% {transform: scale(1);}
    50% {transform: scale(1.1);}
    100% {transform: scale(1);}
}



.react-tabs__tab--selected {
    color: $brand-01;
    border: 1px solid $grey-lighter;
    animation-name: tab-active;
    animation-duration: 0.2s;
}
