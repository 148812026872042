.output-indicators {
  &-cards {
    display: none;
    flex-wrap: wrap;
  }
}

@media (max-width: 950px) {
  .output-indicators {
    .wfp--table {
      display: none;
    }
    &-cards {
      display: flex;
    }
  }
}
