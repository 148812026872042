@import '../colors';


.chart-indicator-panel, .indicator-panel {
  display: flex;
  flex-direction: column;

  &-empty {
    height: 300px;
  }

  &-horizontal {
    flex-direction: row;
  }

  &__disclaimer {
    font-size: 14px;
    font-weight: 600;
    line-height: 23px;
    color: $slate-grey;
  }

  &__top {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    min-height: 310px;

    .indicator {
      display: flex;
    }

    .chart {
      display: flex;
      flex-direction: column;

      &__title {
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: 16px;
        line-height: 26px;
        font-weight: 600;
        color: $navy;

        .subtitle {
          line-height: 23px;
          font-size: 14px;
          font-weight: 600;
          color: $slate-grey
        }
      }
    }

    .chart.center {
      align-items: center;
    }
  }

  &__bottom {
    margin-top: 60px;
    flex-grow: 1;

    &-horizontal {
      margin-top: 0;
    }

    .title {
      display: flex;
      flex-direction: column;
      line-height: 26px;

      font-size: 16px;
      font-weight: 600;

      &__subtitle {
        margin-bottom: 5px;
        line-height: 23px;
        font-size: 14px;
        font-weight: 600;
        font-family: "Open Sans", sans-serif;
        color: $slate-grey;
      }
    }

    .grouped-indicators {
      flex-grow: 1;

      &__item {
        margin-bottom: 10px;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(210px, 1fr));
        grid-column-gap: 10px;

        .label {
          font-size: 14px;
          color: $slate-grey;
        }
      }

      &__item:last-child {
        margin: 0;
      }
    }

    .wfp--tabs__nav-link {
      font-size: 16px;
    }

    .wfp--tabs__nav-item {
      padding: 0;
    }
  }
}


.indicator-panel {
  height: 100%;

  &__bottom {
    margin-top: 0;
    height: 100%;
  }

}

.cbt-panel, .food-panel {
  .chart-indicator-panel__bottom {
    margin-top: 0;
  }
}


.partners-panel-content {
  .chart-indicator-panel {
    height: 100%;
    justify-content: space-between;

    &__bottom {
      margin-top: 40px;
    }

    &__top {
      justify-content: initial;
      min-height: 180px;
    }

    &__disclaimer {
      font-weight: 600;
      font-size: 14px;
      color: $slate-grey;
      margin-bottom: 16px;
    }
  }

  .chart {
    margin: 0
  }
}
