@import "../colors";

.crisis-level {
  cursor: pointer;
  display: flex;
  width: 44px;
  height: 22px;
  justify-content: center;
  align-items: center;
  border-radius: 14px;

  font-size: 13px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  line-height: 1.46;
  color: white;
  text-decoration: none;
  text-transform: uppercase;

  -webkit-transition: background-color 1s ease-out;
  -moz-transition: background-color 1s ease-out;
  -o-transition: background-color 1s ease-out;
  transition: background-color 1s ease-out;
}

.crisis-level.l1 {
  background-color: $blue-grey;
}

.crisis-level.l2 {
  background-color: $orange;
}

.crisis-level.l3 {
  background-color: $cardinal;
}

.crisis-level.m {
  background-color: $nice-blue;
}

.crisis-level.su, .ca, .ar {
  //background-color: $night-shadz;
}

.crisis-level.ca {
  //background-color: $amaranth;
}

.crisis-level.ar {
  //background-color: $jaffa;
}

.emergency-tags {
  display: flex;
  flex-direction: row;

  .crisis-level {
    width: 50px;
    height: 50px;
    border-radius: 0;
    font-size: 24px;
    font-weight: bold;
    margin-right: 10px;
  }
}
