@import "../colors";

.header-general-dashboard {
  min-width: 285px;
  background-color: $navy;

  .header-wrap {
    display: flex;
    flex-direction: column;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    height: 100%;

    .sub-indicators {
      margin-top: 5px;
    }

    .one-line-indicator.total {
      .value {
        font-weight: 700;
      }
    }

    .one-line-indicator {
      justify-content: space-between;

      .label {
        font-size: 16px;
        font-weight: 700;
        font-style: normal;
        font-stretch: normal;
        line-height: 25px;
        letter-spacing: normal;
        margin-right: 15px;
      }

      .value {
        font-size: 16px;
        font-weight: 400;
        line-height: 1.63;
      }
    }

    .title {
      color: $saffron;
      font-size: 16px;
      line-height: 26px;
    }

    &__indicators {
      display: grid;
      grid-gap: 1px;
      background-color: $b-grey;
    }

    &__tags {
      display: flex;
      flex-direction: row;
      margin-bottom: 10px;

      .crisis-level {
        margin-right: 10px;
      }
    }

    .disclaimer-wrap {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    .china-disclaimer {
      display: flex;
      align-items: flex-end;
      font-size: .8em;
      background-color: $navy;
      padding-left: 10px;
      padding-top: 20px;
    }
  }
}

@media only screen and (max-width: 650px) {
  .disclaimer-wrap {
    grid-template-columns: 1fr !important;

    .china-disclaimer {
      padding-left: 0 !important;
    }
  }
}
