@import '../../colors';

.wfp--table {
	.table-images th {
		padding: 0;
	}

	.table-image {
		width: 65px;
		height: 65px;
		margin-bottom: 10px;
		box-sizing: content-box;
	}

	.table-column-title {
		max-width: 140px;
		padding: 0;

		&__inner {
			margin: 15px 0 10px 21px;
			.active {
				background: #ffffff;
			}
		}

		p {
			font-size: 14px;
			font-weight: 600;
			vertical-align: middle;
		}
	}

	.year {
		font-weight: 600;
	}

	th {
		font-weight: 400;
		font-size: 14px;
	}

	thead,
	th {
		background: initial;
		border-right: initial;
		border-bottom: 1px solid $geyser;
		text-align: right;
	}

	.table-images,
	.table-images th {
		border-bottom: initial;
	}

	.active {
		background: #daeffb;
	}

	tr:last-child {
		th {
			border: none;
		}
	}
}
