@import "../../colors";

.stacked-bar-chart-legend {
  display: flex;
  flex-direction: row;
  margin-top: 20px;

  .item {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 10px;

    &__color-section {
      width: 15px;
      height: 15px;
      margin-right: 10px;
    }

    &__title-section {
      font-size: 14px;
      color: $slate-grey;
    }
  }
}
