.wfp-breadcrumbs-light {
  //@include seg-control;
  height: auto;
  //margin: .25em 0;
  margin: 0;
  margin-top: -0.1em;
  text-align: left;
  font-size: 0.9em;
  padding: 0;
  //float: right;
  @include print {
    position: absolute;
    top: -0.2cm;
    right: 0;
  }
  a {
    border-bottom: 0;
  }

  .breadcrumbs--item {
    //@include seg-control--item;
    display: inline;
    border: 0;
    margin: 0;
    &.loading {
      width: 300px;
      height: 20px;
      display: inline-block;
      background-color: $grey-lighter;
    }
    &:first-child {
      border-left: 0;
    }
  }
  
  .breadcrumbs--link {
    //@include seg-control--link;
    padding: 0 0.4em 0 0;
    font-weight: normal;
    color: darken($brand-01, 12%);
    display: inline-block;

    &:after {
      content: '\203A';
      color: $grey;
      font-size: 1.125em;
      margin-left: .5em;
    }

    &.home {
      [class*="icon-"] {
        margin: 0;
      }
        &:before {
          content: "";
          display: inline-block;
          width: 16px;
          height: 16px;
          background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='16' height='16' fill='"+darken($brand-01, 12%)+"' viewBox='0 -5 24 24'%3E%3Cpath d='M10 20v-6h4v6h5v-8h3l-10-9-10 9h3v8z'%3E%3C/path%3E%3C/svg%3E");
        }
      svg {
        path {
          fill: $blue-dark;
        }
      }
      &:hover {
        background-color: inherit;
        color: $brand-01;
      }
      &:after {
        margin-left: .25em;
      }
    }
    &:hover {
      background-color: inherit;
      color: $brand-01;
    }
  }

  .breadcrumbs--last {
    //@include seg-control--link;
    display: inline;

    padding: 0 0.4em 0 0;
    font-weight: normal;
    //color: $grey-dark;
    //cursor: default;

    &:hover {
      background-color: inherit;
      color: inherit;
    }
  }
}
