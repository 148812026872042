@import '../colors';

.about {
  &__item {
    .title {
      font-size: 16px;
      font-weight: bold;
    }

    .sources-list {
      .source {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 5px;
        align-items: center;
        font-size: 14px;

        &__last-update {
          width: fit-content;
          margin-left: 5px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $navy;
        }
      }

      .source:last-child {
        padding-bottom: 10px;
      }

    }
  }

  &__help-text {
    display: grid;
    grid-template-columns: 1fr 1fr;
    font-weight: bold;
    border-bottom: 1px solid $very-light-pink;
    margin-bottom: 5px;
    padding-bottom: 5px;
    font-size: 14px;

    .last-update-title {
      text-align: right;
    }
  }
}