@import "../../colors";


.one-line-indicator {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 12px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;

  .label {
    font-weight: bold;
    min-width: 50%;
  }

  .value {
    font-weight: normal;

    p {
      text-align: right;
      white-space: nowrap;
    }
  }

  .up {
    color: $apple-green;
    font-weight: bold;
  }

  .up::after {
    content: "↑";
    font-size: 22px;
  }

  .down {
    color: $lipstick;
    font-weight: bold;
  }

  .down::after {
    content: "↓";
    font-size: 22px;
  }
}
