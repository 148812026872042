@import '../colors';

.beneficiaries-panel {
	.programme-areas-region-country {
		margin-bottom: 15px;
		padding-bottom: 15px;
		border-bottom: 2px solid #e1e7eb;

		.beneficiaries-panel__legend {
			margin-top: 20px;
		}
	}

	&__main-content {
		padding-right: 10px;
		padding-bottom: 15px;
		background: #fff;
	}

	&__wrap {
		display: grid;
		grid-template-columns: 2fr 1fr;
		grid-gap: 2px;
		background-color: $light-grey;

		.programme-area-chart,
		.projected-charts {
			background-color: #ffffff;
		}

		.programme-area-chart {
			display: flex;
			flex-flow: row wrap;
			justify-content: flex-start;
			margin-bottom: 20px;

			.wrap {
				width: 100%;

				.indicators {
					margin-top: 10px;
					margin-bottom: 20px;

					.bar-indicator-row__container {
						.tooltip {
							width: 70%;
						}
					}
				}
			}
		}

		.modality-chart {
			background: #fff;

			.wrap {
				margin-bottom: 20px;
			}
		}

		.projected-charts {
			padding-left: 10px;
			display: flex;
			flex-flow: row wrap;
			justify-content: flex-start;
			padding-bottom: 15px;

			.legend {
				margin-right: 0;
				margin-bottom: 20px;
			}

			&__residence-status,
			&__by-region {
				width: 100%;
				margin-bottom: 20px;

				.bar-indicator-row__item,
				.bar-indicator__value {
					color: $navy;
					font-size: 14px;
					font-weight: 400;
				}

				.bar-indicator-row__desc {
					width: 90px;
				}
			}
		}
	}

	&__legend {
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;

		.dash-legend {
			margin-top: 0;
			margin-right: 10px;
		}

		.legend {
			margin-right: 10px;
		}
	}

	&__brs-legend {
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
		align-content: flex-start;
		column-gap: 10px;

		.legend,
		.dash-legend {
			margin: 0 !important;
		}
	}

	.title {
		&__subtitle {
			margin-top: 10px;
		}
	}

	.bar-indicator-row__desc {
		width: 340px;

		.bar-indicator-row__item-label {
			width: initial;
		}
	}

	.beneficiaries-total-section {
		border-bottom: 1px solid var(--ui-04);
		margin-bottom: 15px;

		&__indicators {
			display: grid;
			grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
			grid-column-gap: 20px;
			grid-row-gap: 20px;
			padding: 15px 0 20px 0;

			.legend .label,
			.dash-legend__label {
				font-size: 14px;
				font-weight: 700;
				color: #031c2d;
			}

			.dash-legend__label {
				margin-bottom: 2px;
			}
		}
	}
}

@media only screen and (max-width: 1150px) {
	.beneficiaries-panel {
		&__wrap {
			grid-template-rows: 1fr;
			grid-template-columns: none;

			.programme-area-chart {
				padding-right: 0;
				padding-bottom: 20px;
			}

			.projected-charts {
				padding-left: 0;
				padding-top: 20px;
			}
		}
	}
}

@media (max-width: 882px) {
	.beneficiaries-panel {
		.bar-indicator-row__desc {
			width: 250px;
		}
	}
}

@media (max-width: 476px) {
	.beneficiaries-panel {
		.bar-indicator-row__desc {
			width: 180px;
		}

		.legend {
			.mark-wrap {
				margin-bottom: 5px;
			}
		}
	}
}
