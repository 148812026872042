@import "../colors";

.label-tooltip {
  position: absolute;
  top: -25px;
  padding: 4px 5px;
  font-size: 14px;
  font-weight: 300;
  color: $navy;
  background: #eeeeee;
  border-radius: 3px;
  font-family: 'Open Sans', sans-serif;
  white-space: nowrap;
}
