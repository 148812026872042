.detail {
	display: none;
	.share-box & {
		display: flex;
	}
	border-top: 2px solid $grey-lighter;
	margin-top: 1em;
	margin-bottom: 1em;
	width: 100%;
}

.detail__name {
	font-weight: bold;
	flex-grow: 1;
}