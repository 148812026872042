@import "../../colors";


.header-wrap {

  .employees-indicators {
    min-width: 220px;
    background-color: $navy;
    padding-top: 20px;

    .one-line-indicator.total {
      .value, .label {
        font-weight: 700;
      }
    }

    .one-line-indicator {
      .label {
        font-weight: 700;
      }

      .value {
        font-weight: 400;
      }
    }

    .sub-indicators {
      display: flex;
      flex-flow: row wrap;
      font-weight: normal;
      font-size: 12px;
    }

    &__indicator {
      margin-top: 10px;
    }
  }
}
