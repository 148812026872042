@import "../colors";

.strategic-goal-panel {
  .wfp--module__content {
    padding: 0;
  }

  &__table {
    line-height: 1.69;

    .rt-thead .rt-th, .rt-tfoot .rt-td, .rt-tbody .rt-td {
      min-height: 95px;
      padding: 25px 5px;
      border-right: none;
      align-self: flex-start;
      line-height: normal;
    }

    .rt-thead .rt-th{
      padding-right: 20px;
    }

    .rt-tfoot .rt-td.title-column, .rt-tbody .rt-td.title-column {
      padding-left: 30px;
    }

    .rt-tbody .rt-tr-group:last-child {
      border-bottom: solid 1px rgba(0, 0, 0, 0.1);
    }

    .rt-tfoot {
      background-color: $porcelain;
      font-weight: 600;
    }

    .rt-thead {
      background-color: $athens-gray;
    }

    .title-column {
      display: flex;
      flex-direction: row;
      line-height: normal;
      align-items: center;

      &__logo {
        width: 68px;
        height: 68px;
        margin-right: 10px;
      }

      &__label {
        font-weight: 600;

        .code {
          font-size: 14px;
          line-height: 1;
        }
      }
    }

    .two-rows-cell {
      &__percent {
        color: $slate-grey
      }
    }
  }
}
