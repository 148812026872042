@import '../colors';

.card {
	padding: 10px 14px;
	box-sizing: border-box;
	border: 1px solid $geyser;
	min-width: 220px;
	border-radius: 5px;
	height: 100%;
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	&__wrap {
		flex-basis: 33%;
		box-sizing: border-box;
		padding: 10px;
	}

	.wfp-tooltip {
		background: #ffffff !important;
	}

	&__body {
		margin-top: 16px;
	}

	&__title {
		font-size: 14px;
		font-weight: 600;

		&-container {
			display: flex;
			justify-content: space-between;
		}

		&-image {
			width: 65px;
			height: 65px;
			margin-left: 15px;
		}
	}

	.active {
		background: #daeffb;
	}

	&__row {
		padding: 5px;
		display: flex;
		justify-content: space-between;
		border-bottom: 1px solid $geyser;

		&:last-child {
			border-bottom: 0;
		}

		&-label {
			font-size: 14px;
			margin-right: 32px;
			font-weight: 600;
		}

		&-value {
			font-size: 14px;
		}
	}
}

@media (max-width: 794px) {
	.card__wrap {
		flex: 1;
	}
}
