@import "../../colors";

.bar-chart {
  &__legend {
    display: flex;
    flex-flow: row wrap;
    margin-top: 10px;
    margin-bottom: 10px;

    .bar-legend-item {
      font-size: 14px;
      color: $slate-grey;
      padding-right: 5px;
      min-width: 230px;
    }
  }
}