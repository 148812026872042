@import '../colors';

.food-distribution-panel {

  .bar-indicator__value {
    min-width: 75px;
  }

  .bar-indicator {
    .value-suffix {
      margin-right: 0;
    }
  }

  &__disclaimer {
    margin-top: 10px;

    .label, .body {
      color: $dark-grey;
      font-size: 13px;
      line-height: 22px;
      font-family: "Open Sans", sans-serif;
    }

    .label {
      font-weight: 600;
    }
  }
}
