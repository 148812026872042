/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.3.6,
* Autoprefixer: v10.3.1
* Browsers: last 4 version
*/

$ui-01: $color__white !default !global;
$ui-02: $color__navy-gray-9 !default !global;
$ui-03: $color__gray-2 !default !global;
$ui-04: $color__gray-1 !default !global;
$ui-05: $color__navy-gray-7 !default !global;

.content {
  background: $ui-03;
  padding-top: 1em;
  width: 100%;
  overflow: hidden;
  @include print {
    background: none;
  }
}

/* a:visited {
    color: $brand-01;
} */

/* Highlight values and Text in continuous text */

.highlight {
  color: $brand-01;
  font-weight: bold;
}

.main-shadow {
  //box-shadow: 0px 0px 0.5px rgba($brand-01, 0.1), 1px 3px 3px rgba($brand-01, 0.05) !important;
  -webkit-box-shadow: $box-shadow--raised;
  box-shadow: $box-shadow--raised;
}

.right-0 {
  right: 0;
}

.left-0 {
  left: 0;
}

.dn {
  display: none;
}

.m0 {
  margin: 0 !important;
}

.tc {
  text-align: center;
}

.scrollbar {
  ::-webkit-scrollbar {
    width: 7px;
    padding: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $ui-02;
    margin: 10px;
  }
  ::-webkit-scrollbar-thumb:window-inactive {
    background: $ui-02;
    margin: 10px;
  }
}

/* Headingwrap */

.info-react h3, .title {
  font-size: 1.1rem;
  margin-top: 0em; //margin-bottom: 1.9rem;
  margin-bottom: 0;
  line-height: 1.75rem;
  &.title--narrow {
    line-height: 1rem;
    margin-bottom: 1rem;
  }
  @include print {
    margin-left: -1em;
    margin-right: -1em;
    margin-top: -1em;
    margin-bottom: 0.5em;
    padding: 0.8em 1em;
    border-bottom: 1px solid lighten($color__main, 30) !important;
  }
}

.title__note {
  font-weight: normal;
  font-style: italic;
  color: $grey;
}

.title--shorten {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  padding-right: 1em;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

.subtitle {
  font-size: 1rem;
  margin-top: 0em; //margin-bottom: 1.9rem;
  margin-bottom: 0;
  line-height: 1.75rem;
}

.titledescription {
  line-height: 1.2;
  font-size: 0.8rem;
  margin: 0.5em 0.3em 0.5em 0;
  font-weight: 600;
  display: inline-block;
  //color: $brand-01;
}

.articletitle {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0;
  margin-top: 0.5em;
  @include media-max($md-screen) {
    padding: 0 0.2em;
  }
  h2 {
    color: $brand-01;
    font-weight: 300;
    font-size: 1.8rem;
  }
}

.articletitle__link {
  &:after {
    font-family: FontAwesome;
    content: "\f105";
    margin-left: 0.5em;
  }
  @include media-max($md-screen) {
    display: none !important;
  }
}

.description {
  font-style: italic;
}

.description--under {
  @extend .description;
  text-align: right;
}

.heading--smallest {
  font-size: 16px;
  padding-bottom: 4px;
}

.headingwrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  &.headingwrap--left {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  &.headingwrap--nomargin {
    margin-bottom: 0;
  }
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 1em;
  @include media-query($md-screen) {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    margin-bottom: 1.3em;
  }
  margin-top: 0;
  //line-height: 1.75em;
  .h3 {
    margin: 0.3em 0; //flex-basis: 50%;
    -ms-flex-negative: 1;
    flex-shrink: 1;
    line-height: 3em;
    padding-right: 0.2em;
  }
  > select,
  .headingwrap__right {
    font-size: 0.95em !important;
    text-align: right;
    max-width: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    -webkit-box-flex: 2;
    -ms-flex-positive: 2;
    flex-grow: 2;
    > select {
      max-width: 100%;
    }
    a {
      margin-left: 1rem;
    }
    @include media-query($md-screen) {
      max-width: 55%;
      -ms-flex-preferred-size: 45%;
      flex-basis: 45%;
    }
  }
  .headingwrap__center {
    -webkit-box-flex: 2;
    -ms-flex-positive: 2;
    flex-grow: 2;
    @include media-query($md-screen) {
      text-align: right;
      margin-right: 2rem;
    }
  }
  .headingwrap__centerleft {
    text-align: left;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    text-align: center;
    @include media-query($md-screen) {
      margin-left: 2rem;
      margin-right: 2rem;
      > div {
        min-width: 200px;
        width: 25%;
      }
      //max-width: 35%;
      //flex-basis: 35%;
    }
  }
  .headingwrap__right {
    position: relative;
    @include print {
      display: none;
    }
    @include media-query($md-screen) {
      max-width: 35%;
      -ms-flex-preferred-size: 35%;
      flex-basis: 35%;
    }
  }
  .headingwrap__right--wide {
    @include media-query($md-screen) {
      max-width: inherit;
      -ms-flex-preferred-size: 35%;
      flex-basis: 35%;
      -webkit-box-flex: 1;
      -ms-flex-positive: 1;
      flex-grow: 1;
    }
  }
  .headingwrap__right--wide-m {
    @include media-query($md-screen) {
      max-width: 45%;
      -ms-flex-preferred-size: 45%;
      flex-basis: 45%;
    }
  }
  .headingwrap__right--wider {
    @include media-query($md-screen) {
      max-width: inherit;
      -ms-flex-preferred-size: 45%;
      flex-basis: 45%;
      -webkit-box-flex: 1;
      -ms-flex-positive: 1;
      flex-grow: 1;
    }
  }
}

.headingwrap--small {
  margin: 0;
  h3 {
    margin: 0;
    line-height: 2.5rem;
  }
}

.heading-wrap--spacing {
  margin-top: 2rem;
}

.search__wrap {
  position: relative;
  &:before {
    font-family: 'FontAwesome', serif;
    position: absolute;
    height: 100%;
    content: "\f002";
    top: 3px;
    left: 0;
    pointer-events: none;
    margin: 0 0.8em;
    line-height: 1rem;
    color: $text-color;
  }
  input {
    display: block !important;
    padding-left: 2.5em !important;
  }
  &.search__wrap--expand {
    input {
      -webkit-transition: all 0.3s;
      -o-transition: all 0.3s;
      transition: all 0.3s;
      width: 100%;
    }
  }
}

.subscript {
  font-size: 0.7em;
  font-style: headingwrap__right;
  margin-left: 0.3em;
  opacity: 0.8;
}

.noborder {
  border: none !important;
}

.list--large {
  list-style-type: none;
  margin: 1em 0 0 0;
  padding: 0;
  li {
    &:last-child {
      a {
        border-bottom: 0;
      }
    }
  }
  a {
    display: block;
    border-bottom: 0;
    padding: 0.4em 0;
    border-bottom: 1px solid $ui-02;
  }
}

a {
  .material-icons {
    font-size: 1em;
    margin-right: 0.1em;
    position: relative;
    top: 2px;
    .generic_page_content & {
      top: -2px;
    }
    color: $brand-01 !important;
  }
}

.generic_page_content a .map {
  width: 100px;
  height: 100px;
}

.wfp-wrapper--tight {
  padding: 0;
  @include media-query($md-screen) {
    //padding: 0 1rem;
  }
}

.value--largercenter {
  text-align: center;
  margin: auto;
  .value__title {
    font-size: 3rem;
    @include media-query($xl-screen) {
      font-size: 4rem;
    }
    line-height: 0.7em;
    font-weight: 400;
    margin: 0;
    margin-top: 1rem;
  }

  .value_subtitle {
    font-size: 1.3rem;
    line-height: 1.2em;
    font-style: italic;
    margin: 0.5em 0 0.3em 0;
    color: $grey;
    font-weight: 400;
  }
  .value__description {
    color: $brand-01;
    line-height: 1.3em;
    font-size: 0.9rem;
    margin: 0.4em 0;
    white-space: pre-line;
  }
}

.value--large {
  .value__title {
    font-size: 2rem;
    @include media-query($xl-screen) {
      font-size: 3rem;
    }
    font-weight: 400;
    margin: 0;
  }
  .value__difference {
    font-size: 0.8em;
    cursor: pointer;
    position: relative;
    top: 0.5em;
  }
  .value__icon {
    font-size: 3.5em;
    display: inline-block;
    margin-left: 0.2em;
    margin-right: 0.4rem;
    position: relative;
    top: -1rem;
  }
  .value__icon-text {
    display: inline-block;
  }

  .value__description {
    color: $brand-01;
    line-height: 1;
    font-size: 0.8rem;
    margin: 0;
  }
}

.value--medium {
  //margin: 1.5em 0;
  .value-select & {
    margin-top: 0;
  }
  .value__title {
    font-size: 1rem;
    /*@include media-query($xl-screen) {
        font-size: 1.2rem;
    }*/
    //white-space: nowrap;
    @include media-query($sm-screen) {
      font-size: 1rem;
    }
    @include media-query($xl-screen) {
      font-size: 1.2rem;
    }
    font-weight: 400;
    margin: 0;
    margin-right: -1.4em;
    > .unit, > .wfp--unit {
      margin-right: 0.2rem;
      font-weight: 600;
    }
  }
  .value__difference {
    font-size: 0.8em;
    cursor: pointer;
    position: relative;
    top: 0.5em;
  }
  .value__secondary {
    font-size: 0.75rem;
    @include media-query($sm-screen) {
      font-size: 0.65rem;
    }
    @include media-query($xl-screen) {
      font-size: 0.75rem;
    }
    line-height: 1;
    display: inline-block;
    //white-space: nowrap;
  }
  .value__icon {
    font-size: 3.5em;
    display: inline-block;
    margin-left: 0.2em;
    margin-right: 0.4rem;
    position: relative;
    top: -1rem;
  }
  .value__icon-text {
    display: inline-block;
  }

  .value__description {
    //color: $brand-01;
    line-height: 1.3;
    font-size: 0.8rem;
    margin: 0;
    margin-top: 0.5em;
  }
  .value__chart {
    margin-left: 0.6em;
    margin-top: -0.6em;
    margin-bottom: -1em;
  }
}

.value--space {
  margin-top: 1em;
  margin-bottom: 1em;
  @include media-query($xl-screen) {
    margin-top: 1.5em;
    margin-bottom: 1.5em;
  }
  /* &:nth-child(2) {
      margin-top: 1em;
  } */
}

.value--large--right {
  margin-top: -1em;
  margin-bottom: -2.5em;
}

.value--small {
  font-size: 1.3em;
  .value__title {
    color: $brand-01;
    display: block;
    font-weight: bold;
    font-size: 0.7em;
    white-space: nowrap;
  }
}

.value__date {
  font-size: 0.8em;
  font-style: italic;
  color: $grey;
}

/* Value Selector */
.value-select {
  //margin-top: 1.5em;
  .hidden {
    display: none;
  }
}

.value-select__selector {
  display: inline-block !important;
  line-height: 1;
  margin-bottom: 0.2em;
}

.value-select__selector__content {
  margin: -0.7em -0.9em;
  line-height: 1.5rem;
  > div {
    display: block;
    border-bottom: 1px solid $ui-02;
    &:last-child {
      border-bottom: none;
      a {
        margin-bottom: 0;
      }
    }
  }
  a {
    display: block;
    padding: 0.3em 1em;
    border-bottom: none;
  }
}

.value-select__trigger {
  color: $brand-01;
  line-height: 1;
  font-size: 0.8rem;
  margin: 0;
  font-weight: 600;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  &:after {
    font-family: FontAwesome;
    content: "\f107";
    margin-left: 0.5em;
  }
}

.value__description {
  line-height: 1;
  font-size: 0.8rem;
  margin: 0;
  margin-bottom: 0.2rem;
  display: inline-block;
  font-weight: 600;
  padding: 0;
}

.valuelist {
  margin: 0;
  padding: 0;
  list-style-type: none;
  white-space: nowrap;
  font-weight: 400;
  li {
    margin: 0.4em 0;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .valuelist__info {
    font-size: 0.8em;
  }
  .valuelist__title {
    font-weight: bold;
    margin-right: 0.5em;
    letter-spacing: -0.025rem;
  }
  .valuelist__label {
    //font-weight: bold;
    margin-right: 1em;
    &:after {
      content: ":";
    }
  }
  .valuelist__highlight {
    display: inline-block;
    font-weight: bold;
    font-size: 0.9em;
    margin-right: 0.5em;
    color: $white;
    background-color: $brand-01;
    border-radius: 3px;
    padding: 0.1em 0.4em;
  }
}

.valuelist--mini {
  font-size: 0.8em;
  line-height: 1.3em;
}

.valuelist--flex {
  li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
  }
  .valuelist__title {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1
  }
  .valuelist__value {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    text-align: right;
  }
}

.valuelist__seperated {
  border-top: 1px solid $ui-02;
  padding-top: 0.3em;
}

.valuelist--columns {
  li {
    white-space: normal;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
  }
  .valuelist__title {
    //flex: 1;
    width: 40%;
    min-width: 40%;
  }
  .valuelist__value {
    //flex-grow: 1;
    width: 60%;
    text-align: left;
  }
}

.valuelist--seperator {
  li {
    border-bottom: 1px solid $ui-02;
    &:last-child {
      border-bottom: none;
    }
  }
}

.legend {
  list-style-type: none;
  margin: 0;
  padding: 0;
  font-size: 0.7rem;
  line-height: 1.0rem;


  &__label {
    font-style: normal;
    font-weight: normal;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;

    .bold {
      font-weight: bold;
      color: #000;
    }

    .percents {
      width: 40px;
      text-align: right;
    }

    .value {

      &-container {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
      }

      text-align: right;
    }
  }

  li {
    width: 50%;
    display: inline-block;
  }

  &--bar-chart {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-item-align: end;
    align-self: flex-end;
    font-weight: bold;
    font-size: 0.8rem;
    padding: 15px 0 5px 0;
  }

  &--pyramid-bar-chart {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    font-weight: bold;
    font-size: 0.8rem;
    padding: 15px 0 5px 0;
  }
}

.legend--small {
  font-size: 0.6em;
  li {
    width: 33%;
    white-space: nowrap;
  }
}

.legend--smallleft {
  font-size: 0.6em;
  li {
    white-space: nowrap;
  }
}

.legend--smallleftlist {
  font-size: 0.6em;
  li {
    white-space: normal;
    width: 100%;
    display: block;
  }
}

.legend__color {
  width: 1.2em;
  height: 1.2em;
  position: relative;
  top: 0.17em;
  margin-right: 0.3em;
  display: inline-block;
}

.rank {
  cursor: pointer;
}

.rank__up,
.rank__down,
.rank__plus,
.rank__minus {
  &:before {
    content: "▲";
  }
}

.rank__plus {
  color: $green;
  &:before {
    content: "+";
  }
}

.rank__minus {
  color: $red;
  &:before {
    content: "-";
  }
}

.rank__up {
  color: $green;
}

.rank__down {
  color: $red;
  &:before {
    content: "▼";
  }
}

.previousyear__small {
  font-style: italic;
  margin-left: 0.3em;
  opacity: 0.8;
  font-size: 0.7em;
}

// Order Element
.order {
  white-space: nowrap;
  margin-right: 1em;
  @include media-max($sm-screen) {
    margin-bottom: 1em;
  }
}

.order__text {
  margin-right: 1em;
}

.order__link {
  color: $text-color;
  cursor: pointer;
  margin: 0 0.3em;

  &.active {
    color: $brand-01;
    font-weight: bold;
    text-decoration: underline;
  }
}

.order__link:hover{
  text-decoration: underline;
}

.order__link:last-child{
  text-transform: lowercase;
}

.inverted {
  color: $white;
  background: $color__main;
  background: -o-linear-gradient(305deg, darken($color__main, 10) 0%, lighten($color__main, 2) 100%);
  background: linear-gradient(145deg, darken($color__main, 10) 0%, lighten($color__main, 2) 100%);
  background: -o-linear-gradient(305deg, lighten($color__main, 10) 2%, lighten($color__main, 15) 100%);
  background: linear-gradient(145deg, lighten($color__main, 10) 2%, lighten($color__main, 15) 100%);
  border: none;
  h1, h2, h3, h4, a, .value__description {
    color: $white;
  }
}

.disclaimer {
  text-align: right;
  font-style: italic;
  color: $color__main;
  font-size: 0.9em;
}

.inverted--lighter {
  @extend .inverted;
  background: desaturate($color__main, 20) !important;
}

.story {
  font-size: 0.95em;
}
