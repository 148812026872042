@import "../colors";

.kpi-1, .kpi-2, .kpi-3 {
  &__content {
    margin-top: 30px;
  }
}

.kpi-2 {
  &__content {
    display: flex;
    flex-direction: column;
  }

  &__chart-title {
    color: $navy;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    align-self: center;
    align-items: center;
  }
}

.kpi-1 {
  margin-top: 20px;

  &__content {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    grid-gap: 10px;
    margin-top: 0;
  }

  &__chart-wrap:first-child {
    border-right: 2px solid #eff2f5;
  }

  &__chart-wrap {
    padding: 0 10px;
    display: flex;
    flex-direction: column;
  }

  &__chart-title {
    font-size: 18px;
    font-weight: 600;
    color: $navy;
    min-height: 50px;

    .sub-title {
      font-size: 12px;
      color: $blue-grey;
    }
  }
}

@media screen and (max-width: 768px) {
  .kpi-1 {

    &__content {
      grid-template-columns: 1fr;
    }

    &__chart-wrap:first-child {
      border-right: none;
    }
  }
}
