@import "../colors";

.focus-areas {

  .legend {
    margin-left: 5px;
  }

  &__disclaimer {
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
    margin-top: 3px;
    margin-bottom: 24px;
    color: $slate-grey;
    font-weight: 600;

    &-title {
      font-size: 16px;
      line-height: 26px;
      font-weight: 600;
      color: $navy;
    }
  }

  &-charts {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  &-chart {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 20px;
    padding: 0 5px;

    .chart-v2 {
      margin-top: -25px;
    }

    &__container {
      flex-grow: 1;
      max-width: 290px;
    }

    .chart__title {
      font-size: 16px;
      font-weight: 600;
      color: #000;
    }

    .chart-v2 {
      justify-content: center;
      flex-direction: row;

      .doughnut-chart .center-text {
        font-size: 16px;
      }
    }

    .desc {
      margin-top: 20px;
      font-family: 'Open Sans', sans-serif;
      font-size: 14px;

      &__value {
        padding-right: 10px;
        line-height: normal;
        letter-spacing: normal;
        white-space: nowrap;
        justify-self: center;
        align-self: center;
        text-align: right;
        min-width: 40px;
      }

      &__values {
        display: flex;
      }

      &__label {
        font-weight: 600;
      }

      .desc__item {
        display: flex;
        justify-content: space-between;
        margin-bottom: 7px;
      }
    }
  }
}

//@media (max-width: 1100px) {
//  .focus-areas {
//    &-charts {
//      justify-content: center;
//    }
//  }
//}
