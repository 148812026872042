@import "colors";

.error-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 70px 20px;
  height: 90vh;

  &__icon {
    transform: rotate(10deg);
  }

  &__title {
    font-size: 3.375rem;
    font-weight: 300;
    margin: 44px 0;
  }

  &__link {
  }
}
