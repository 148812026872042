@import "../../colors";

.info-source {
  &__item {
    font-size: 14px;
    line-height: 1.36;
    color: $navy;
    margin-bottom: 15px;

    .title {
      font-weight: bold;
      font-size: 14px;
    }

    .info {
      display: flex;
      flex-flow: row wrap;
    }
  }
}
