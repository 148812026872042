
.environment-indicator {
  position: fixed;
  width: 300px;
  height: 50px;
  background: #ffbf3b;
  top: 60px;
  right: -90px;
  text-align: center;
  font-size: 20px;
  font-family: sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  color: #fff;
  line-height: 50px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  opacity: .8;
  padding-left: 30px;
  z-index: 10;
}

.environment-indicator.alpha{
  background: #ffbf3b;
}

.environment-indicator.beta{
  background: #c5192d;
}

.environment-indicator.qa{
  background: #8ad220;
}

.environment-indicator.kitten{
  background: rgb(252, 199, 201);
}
