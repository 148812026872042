// -----------------------------------------------------------------------------
// Quicksearch style for search textfields
// -----------------------------------------------------------------------------


.react-autosuggest__input {
    padding: 0;
    height: auto;
}

.react-autosuggest__suggestions-container {
    position: absolute;
    top: inherit;
    right: 0;
    margin-top: 5px;
    background: $white;
    color: $black;
    width: 100%;
    max-height: 70vh;
    overflow-x: hidden;
    overflow: auto;
    z-index: 10000;
    //border-radius: 3px;
    box-shadow: rgba($black, 0.1) 0 3px 13px;
    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }
    li {
        margin: 0;
        padding: 0;
        >div {
            cursor: pointer;
            padding: 0.5em 1em;
            border-bottom: 1px solid $grey-lighter;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            &:hover {
              background: $blue-lightest;
              border-bottom: 1px solid darken($blue-lightest,5);
            }
        }
    }
    .react-autosuggest__section-title {
        padding: 0.5em 1em;
        background: $grey-lightest;
        /* Fix */
        font-size: 1rem;
        >strong {
            margin-left: 0;
            line-height: 1em;
            &:before {
                display: none;
            }
        }
    }
}

.react-autosuggest__suggestion--highlighted {
    background: darken($blue-lightest,5);
}

.quicksearch {
    margin: 0;
    position: relative;
    font-weight: normal;
    .form-item .form-text {
        border: 1px solid $blue;
    }

    &.views-exposed-form {
        position: relative;
        .wfp-btn {
            right: 4px;
            top: 10%;
        }
    }

    @include media-query($md-screen) {
        margin-top: 0;
        position: relative;
        &.quicksearch--small {
            .form-item .form-text,
            .react-autosuggest__input {
                border: 0;
                width: auto;
                &.react-autosuggest__input--focused {
                    width: auto;
                }
                @include media-query($lg-screen) {
                    width: auto;
                    &.react-autosuggest__input--focused {
                        width: auto;
                    }
                }
                font-size: 1rem;
                transition: width 0.15s;
                border-radius: 3px;
            }
        }
        &.quicksearch--bordered {
            .form-item .form-text,
            .react-autosuggest__input {
                border: 1px solid $blue-dark;
            }
        }
    }
    //.form-item {
        //display: inline;
        .form-text,
        .react-autosuggest__input {
            //padding: 0 .5em 0 2em;
            //width: 100%;
            //height: 2.5em;
            //line-height: 2.75em;
            //color: $text-color; // TODO: Remove in 4.0
            &::-webkit-input-placeholder {
                font-style: italic;
                color: $grey;
            }
            &:-moz-placeholder {
                font-style: italic;
                color: $grey;
            }
            &::-moz-placeholder {
                font-style: italic;
                color: $grey;
            }
            &:-ms-input-placeholder {
                font-style: italic;
                color: $grey;
            }
        //}
    }
}

.suggestion__content {
    >name {
        font-weight: normal;
    }
}

.suggestion__content.suggestion__content-empty {
    background: #fff;
    cursor: initial;
}

.suggestion__small {
    font-size: 0.75rem;
    font-style: italic;
    color: $grey;
    display: block;
}

/**
 * Search Suggest
 *
 * The Suggestions when searching
 * found on all pages in the header
 */

.results-row {
    a {
        display: block;
        border-bottom: 1px solid $grey-lighter;
        padding: 1em;
        text-align: left;
        transition: all 0.1s;
        &:hover {
            background-color: $blue-lightest;
        }
    }
}

.results-primary-text {

}

.results-secondary-text {
    font-size: 0.8em;
    display: block;
    color: $grey;
}
