.banner {
	background-color: darken($blue-lightest,5);
	padding: 2em 0;
	@include media-max($md-screen) {
        padding: 2em 0.2em;
    }
	h3 {
		font-style: italic;
		color: darken($blue,7);
		font-weight: 400;
		font-size: 1.1em;
		letter-spacing: 0;
		cursor: pointer;
		line-height: 4em;
		/*&:before {
			font-style: normal;
			font-family: FontAwesome;
	        content: "\f071";
	        display: inline-block;
	        margin-right: 0.5em;
	        color: $blue-darker;
	        vertical-align: middle;
	        font-size: 2em;
	        transition: all 0.3s;
		}*/
		&:after {
			font-style: normal;
			font-family: FontAwesome;
	        content: "\f105";
	        display: inline-block;
	        margin-left: 0.5em;
	        transition: all 0.3s;
		}
		&:hover {
			&:after {
				transform: translateX(5px);
			}
		}
	}
}

.banner--top {
	margin-top: -1em;
}

.banner--bottom {
	margin-top: 2em;
}

.banner.banner--dark {
	@extend .inverted;
}

.banner.banner--lighter {
	@extend .inverted--lighter;
}