// colors
$navy: #031c2d;
$greyish-brown: #4a4a4a;
$blue-grey: #8c9ba5;
$slate-grey: #5a6872;
$very-light-pink: #dedede;
$grey-blue: #4997AE;
$secondary-blue: #0087cb;
$water-blue: #0b77c2;
$big-stone: #1A3748;
$lighter-blue: #C2DBEC;
$dark-big-stone: #162835;
$pickled-bluewood: #344D5C;
$blue-dianne: #213746;
$black: #000000;
$light-grey: #E1E7EB;
$light-grey-border: #EDF1F3;

// for charts
$fountain-blue: #60ADC4;
$fiord: #395366;
$dark-grey: #5A6872;
$light-blue: #9DEAFF;

$saffron: #F7B825;
$sunset-orange: #FF5252;
$orange: #fd6926;
$cardinal: #C5192C;
$night-shadz: #982B56;
$amaranth: #EF404C;
$jaffa: #F47847;


$apple-green: #8ad220;
$lipstick: #c5192d;
$athens-gray: #EFF2F5;
$geyser: #DFE3E6;
$nice-blue: #0a6eb4;
$dark-blue: #005e8d;
$bar-dark-blue: #005F9B;
$bar-blue: #0780C0;
$bar-light-blue: #73C3FE;
$bar-light-blue-third: rgb(157, 234, 255);
$bar-header-blue: #4AA1E2;
$error: #c5192d;
$error-transparent: rgba(197, 25, 45, 0.8);

$b-grey: rgba(255, 255, 255, 0.22);
$geyser: #dfe3e6;
$nobel: #B6B6B6;
$mercury: #E6E6E6;
$porcelain: #FBFCFC;
$athens-gray: #EFF2F5;
