@import "../colors";

.header-bottom-indicators {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
  grid-column-gap: 5px;
  grid-row-gap: 20px;

  border-top: 1px solid $b-grey;
  background-color: $navy;
  padding: 20px 5px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
